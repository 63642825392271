// @flow
import React from 'react'
import cx from 'classnames'
import classes from './Loader.css'

type Props = {
  size: 'small' | 'normal' | 'large',
  style?: Object,
}

export default class Loader extends React.PureComponent<Props> {
  static defaultProps = {
    size: 'normal',
  }

  static scale = {
    small: 1,
    normal: 2,
    large: 4,
  }

  render() {
    const { size, style, ...props } = this.props
    return <div {...props} className={cx(classes.loader, classes[size])} />
  }
}
