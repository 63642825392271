// @flow
import * as React from 'react'
import { Switch, Route } from 'react-router-dom'
import { mapValues } from 'lodash'
import history from 'routes/history'
import settings from 'core/settings'
import api from 'core/api'
import ReactTable from 'components/ReactTable'
import { Edit, Text, Select } from 'components/ReactTable/Cells'
import CellTextRead from 'components/ReactTable/Cells/Common/TextRead/TextRead.jsx'
import Button from 'components/Buttons/Button'
import { ModalTrigger } from 'components/Modal'
import Form from 'components/Form/ReactForm/Form/Form.jsx'
import Input from 'components/Form/ReactForm/Input/Input.jsx'
import Field from 'components/Form/Fields/Field/Field.jsx'
import SelectForm from 'components/Form/ReactForm/Select/Select.jsx'
import type { Country, Team, Stadium } from 'core/types'
import Title from 'components/Texts/Title/Title.jsx'
import { getStandingsFromLocal, getLeaguesFromLocal } from 'core/providers/apiFootball'
import { ObjectValues } from 'utils/flowPolyfills'
import pipe from 'core/utils/pipe'
import classes from './Teams.css'
import { ModalCreateTeam } from './ModalCreateTeam.jsx'

type Props = {
  countries: { [countryId: string]: Country },
  teams: { [teamId: string]: Team },
  stadiums: { [stadiumId: string]: Stadium },
  resources: Object,
  teamId: string,
  countryId: string,
  apiFootballLeagues: Array<Object>,
}

type State = {
  standings: Array<Object>,
  apiFootballLeagueId: ?string,
}

const fetchTeamByCountry = (resources, countryId) =>
  resources.teams.fetch(undefined, undefined, {
    params: { filter: { where: { countryId } } },
  })
const fetchStadiumByCountry = (resources, countryId) =>
  resources.stadiums.fetch(undefined, undefined, {
    params: { filter: { where: { countryId } } },
  })
class FileSelector extends React.Component<{ teamId: string, resources: any }> {
  constructor(props: any) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = files => {
    const file = files[0]
    api
      .teams()
      .presignedURL(undefined, { params: { name: `${this.props.teamId}.png` } })
      .then(res => {
        const { url } = res.data
        fetch(url, {
          method: 'PUT',
          body: file,
        }).then(() => {
          this.props.resources.teams({ id: this.props.teamId, hasLogo: true }).update()
        })
      })
  }

  render() {
    return (
      <div>
        <input type="file" onChange={e => this.handleChange(e.target.files)} />
      </div>
    )
  }
}

class Teams extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      standings: [],
      apiFootballLeagueId: null,
    }

    if (props.teamId) {
      const team = props.teams[props.teamId]
      if (team.providers.apifootball) {
        this.state.standings.push({
          team_name: team.providers.apifootball.name,
        })
      }
    }
  }

  onChangeCountries = ({ value: countryId }) => {
    if (!countryId) return
    const { teams, resources } = this.props
    history.push(`/teams/${countryId}`)
    const teamsExist = !!ObjectValues(teams).find(team => team.countryId && team.countryId === countryId)
    if (!teamsExist) {
      fetchTeamByCountry(resources, countryId)
      fetchStadiumByCountry(resources, countryId)
    }
  }

  onChangeTeam = ({ value: teamId }) => {
    if (!teamId) return
    history.push(`/teams/${this.props.countryId}/${teamId}`)
  }

  onSubmit = values => {
    const { resources, teamId } = this.props
    return resources
      .teams({
        id: teamId,
        name: values.name,
        shortName: values.shortName,
        stadiumId: values.stadiumId,
      })
      .update()
  }

  onSubmitProviders = providers => {
    const { resources, teamId } = this.props
    return resources
      .teams({
        id: teamId,
        providers,
      })
      .update()
  }

  onChangeApiFootballLeagues = ({ value: leagueId }) => {
    getStandingsFromLocal(leagueId).then((standings: Array<Object>) => {
      this.setState({ standings, apiFootballLeagueId: leagueId })
    })
  }

  onSave = data => {
    return this.props.resources.teams(data).update()
  }

  render() {
    const { countries, stadiums, teams, countryId, apiFootballLeagues } = this.props
    const { standings, apiFootballLeagueId } = this.state

    return (
      <div className={classes.container}>
        <div className={classes.buttonContainer}>
          <ModalTrigger modal={<ModalCreateTeam countries={countries} />}>
            <Button>Create Team</Button>
          </ModalTrigger>
        </div>
        <div className={classes.content}>
          <ReactTable
            data={Object.values(teams)}
            onSave={this.onSave}
            columns={[
              {
                Header: '',
                fixed: 'left',
                columns: [
                  Edit(),
                  Text({
                    Header: 'Name',
                    accessor: 'name',
                    filterable: true,
                    enableEdit: true,
                    width: 250,
                  }),
                ],
              },
              Text({
                Header: 'ID',
                accessor: 'id',
                width: 250,
              }),
              Text({
                Header: 'Short name',
                accessor: 'shortName',
                enableEdit: true,
                filterable: true,
                width: 200,
              }),
              Select({
                Header: 'Has Logo',
                id: 'hasLogo',
                accessor: item => (item.hasLogo ? 'Yes' : 'No'),
                accessorEdit: 'hasLogo',
                enableEdit: true,
                filterable: true,
                width: 200,
                options: [
                  {
                    value: true,
                    label: 'Yes',
                  },
                  {
                    value: false,
                    label: 'No',
                  },
                ],
              }),
              {
                Header: 'Logo',
                accessor: 'logo',
                Cell: props => {
                  const link = `${settings.env.staticURL}/${props.row.id}.png`
                  return (
                    <a href={link} target="_blank">
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={link} height="40" alt={props.row.name} />
                      </div>
                    </a>
                  )
                },
              },
              {
                Header: 'Change logo',
                id: 'change_logo',
                accessor: () => null,
                Cell: props => <FileSelector teamId={props.row.id} resources={this.props.resources} />,
              },
              Text({
                Header: 'Website',
                accessor: 'website',
                enableEdit: true,
                width: 300,
                // TODO Link
                Cell: row => (
                  <CellTextRead>
                    <a href={row.value} target="_blank">
                      {row.value}
                    </a>
                  </CellTextRead>
                ),
              }),
              Select({
                Header: 'Country',
                id: 'countryId',
                accessor: item => countries[item.countryId].name,
                accessorEdit: 'countryId',
                options: ObjectValues(countries).map(country => ({
                  value: country.id,
                  label: country.name,
                })),
                filterable: true,
                enableEdit: true,
                width: 200,
              }),
              {
                Header: 'Stadium',
                getHeaderProps: () => ({
                  style: {
                    backgroundColor: '#057edf',
                    color: '#fff',
                  },
                }),
                columns: [
                  Select({
                    Header: 'Name',
                    id: 'stadiumId',
                    // accessor: item => stadiums[item.stadiumId].name,
                    accessor: 'stadium.name',
                    accessorEdit: 'stadiumId',
                    options: row =>
                      ObjectValues(stadiums)
                        .filter(({ countryId }) => countryId === row.original.countryId)
                        .map(stadium => ({
                          value: stadium.id,
                          label: stadium.name,
                        })),
                    filterable: true,
                    enableEdit: true,
                    width: 200,
                  }),
                  Text({
                    Header: 'City',
                    accessor: 'stadium.city',
                    filterable: true,
                    width: 200,
                  }),
                  {
                    Header: 'Address',
                    accessor: 'stadium.addressFull',
                    Cell: row => {
                      if (!row.value) return ''
                      return (
                        <CellTextRead>
                          <a href={`http://maps.google.com/?q=${row.value}`} target="_blank">
                            {row.value}
                          </a>
                        </CellTextRead>
                      )
                    },
                    filterable: true,
                    width: 300,
                  },
                ],
              },
              {
                Header: 'Providers',
                getHeaderProps: () => ({
                  style: {
                    backgroundColor: '#fb0000',
                    color: '#fff',
                  },
                }),
                columns: [
                  // Text({
                  //   Header: 'XMLSoccer',
                  //   accessor: 'providers.xmlsoccer.id',
                  //   width: 100,
                  // }),
                  Text({
                    Header: 'Api-Football',
                    id: 'providers.apifootball',
                    accessor: item => {
                      if (item.connector) {
                        const providerTeamName =
                          item.connector.providerTeamId === item.connector.plainData.homeTeam_id
                            ? item.connector.plainData.homeTeam
                            : item.connector.plainData.awayTeam

                        return providerTeamName
                      }

                      return ''
                    },
                    width: 200,
                    // ModalEdit: row => {
                    //   const { id, providers } = row.original
                    //   return (
                    //     <ModalConfirmForm title="Edit">
                    //       <Form
                    //         // values={}
                    //         onSubmit={({ name }) => {
                    //           if (!name) return Promise.resolve(true)
                    //           const { resources } = this.props
                    //           const teamData = {
                    //             id,
                    //             providers: {
                    //               ...providers,
                    //               apifootball: { name },
                    //             },
                    //           }
                    //           return resources.teams.update(teamData)
                    //         }}
                    //         render={() => (
                    //           <div>
                    //             <Field label="Select league">
                    //               <SelectField
                    //                 options={apiFootballLeagues.map(league => ({
                    //                   value: league.league_id,
                    //                   label: league.league_name,
                    //                   countryName: league.country_name,
                    //                 }))}
                    //                 onChange={this.onChangeApiFootballLeagues}
                    //                 formatOptionLabel={({ label, countryName }) => {
                    //                   return (
                    //                     <div>
                    //                       {label} &nbsp; ({countryName})
                    //                     </div>
                    //                   )
                    //                 }}
                    //               />
                    //             </Field>
                    //             <SelectForm
                    //               key={apiFootballLeagueId}
                    //               label="Select team"
                    //               field="name"
                    //               options={standings.map(team => ({
                    //                 value: team.team_name,
                    //                 label: team.team_name,
                    //               }))}
                    //             />
                    //           </div>
                    //         )}
                    //       />
                    //     </ModalConfirmForm>
                    //   )
                    // },
                  }),
                ],
              },
            ]}
          />
          {/*
          <Field label="Select country">
            <Select
              options={ObjectValues(countries).map(country => ({
                value: country.id,
                label: country.name,
                isSelected: country.id === countryId,
              }))}
              onChange={this.onChangeCountries}
              value={
                countryId && {
                  value: countryId,
                  label: countries[countryId].name,
                }
              }
            />
          </Field>
          <Field label="Select team">
            <Select
              key={countryId}
              options={ObjectValues(teams)
                .filter(team => team.countryId === countryId)
                .map(team => ({
                  value: team.id,
                  label: team.name,
                }))}
              onChange={this.onChangeTeam}
              value={teamId && { value: teamId, label: teams[teamId].name }}
            />
          </Field> */}
          <Switch>
            <Route
              path="/teams/:countryId/:teamId"
              render={routeProps => {
                const {
                  match: { params },
                } = routeProps
                const { teamId }: Object = params
                const { teams, stadiums } = this.props
                const team = teams[teamId]
                return (
                  <div className={classes.teamContent}>
                    <div>
                      <Title size="3">Infos</Title>
                      <Form
                        values={team}
                        submitButtonLabel="Save"
                        onSubmit={this.onSubmit}
                        render={() => (
                          <div>
                            <Input label="Team name" field="name" />
                            <Input label="Team short name" field="shortName" />
                            <SelectForm
                              label="Stadium"
                              field="stadiumId"
                              options={ObjectValues(stadiums)
                                .filter(stadium => stadium.countryId === countryId)
                                .map(stadium => ({
                                  value: stadium.id,
                                  label: stadium.name,
                                }))}
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div>
                      {/* Providers */}
                      <div>
                        <Title size="3">Providers</Title>
                        <Form
                          values={team.providers}
                          onSubmit={this.onSubmitProviders}
                          submitButtonLabel="Save"
                          render={() => (
                            <React.Fragment>
                              <Title size="4">XML Soccer</Title>
                              <Input label="XMLSoccer ID" field="xmlsoccer.id" />
                              <Title size="4">API Football</Title>
                              {/* <Input label="API football" field="apifootball.id" /> */}
                              <Field label="Select league">
                                <Select
                                  options={apiFootballLeagues.map(league => ({
                                    value: league.league_id,
                                    label: league.league_name,
                                    countryName: league.country_name,
                                  }))}
                                  onChange={this.onChangeApiFootballLeagues}
                                  formatOptionLabel={({ label, countryName }) => {
                                    return (
                                      <div>
                                        {label} &nbsp; ({countryName})
                                      </div>
                                    )
                                  }}
                                />
                              </Field>
                              <SelectForm
                                key={apiFootballLeagueId}
                                label="Select team"
                                field="apifootball.name"
                                options={standings.map(team => ({
                                  value: team.team_name,
                                  label: team.team_name,
                                }))}
                              />
                            </React.Fragment>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )
              }}
            />
          </Switch>
        </div>
      </div>
    )
  }
}

const getTeams = state => {
  return mapValues(state.teams.resources, team => ({
    ...team,
    stadium: state.stadiums.resources[team.stadiumId],
    country: state.countries.resources[team.countryId],
  }))
}

export default pipe()
  .resources()
  .connect((state, props) => ({
    countries: state.countries.resources,
    // teams: state.teams.resources,
    teams: getTeams(state),
    stadiums: state.stadiums.resources,
    teamId: props.match.params.teamId,
    countryId: props.match.params.countryId,
    key: props.match.params.teamId,
  }))
  .request(({ resources }) => {
    return Promise.all([
      resources.countries().fetch(),
      resources.teams().fetch({
        params: {
          filter: {
            include: [{ relation: 'connector' }],
          },
        },
      }),
      resources.stadiums().fetch(),
      getLeaguesFromLocal(),
    ])
  })
  .mapRequestToProps(res => ({ apiFootballLeagues: res[1] }))
  .render(Teams)
