import Login from './Login.jsx'
import Logout from './Logout.jsx'
// import Register from './Register.jsx';
import Layout from './Layout.jsx'

export {
  Login,
  Logout,
  // Register,
  Layout,
}
