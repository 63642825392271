// @flow

import * as React from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactTableContext } from '../../ReactTable.jsx'
import classes from './Edit.css'

// type Props = {}

// const getColumnId = (data: Object) => data.id || data.accessor

export default ({ icon, onClick, ...data }: Object = {}) => ({
  Cell: (row: Object) => (
    <ReactTableContext.Consumer>
      {context => {
        if (!context) return null
        const { id } = row.original
        const isEditing = context.rowsBeingEdit[id]

        if (!isEditing) {
          return (
            <div
              className={classes.container}
              onClick={() => context.startRowEdit(id)}
            >
              <div className={classes.icon}>
                <FontAwesomeIcon icon="edit" />
              </div>
            </div>
          )
        }

        return (
          <div className={classes.container}>
            <div
              onClick={() => context.endRowEdit(id)}
              className={cx(classes.icon, classes.iconCancel)}
            >
              <FontAwesomeIcon icon="times-circle" />
            </div>
            <div
              className={cx(classes.icon, classes.iconSave)}
              onClick={() => context.save(id)}
            >
              <FontAwesomeIcon icon="save" />
            </div>
          </div>
        )
      }}
    </ReactTableContext.Consumer>
  ),
  width: 100,
  ...data,
})
