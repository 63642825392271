// @flow
import React from 'react'
import { logout } from 'store/actions/user'
import store from 'store'

export default class Logout extends React.Component<void> {
  componentDidMount() {
    store.dispatch(logout())
  }

  render() {
    return <div>Logout...</div>
  }
}
