// @flow
import * as React from 'react'
import cx from 'classnames'
import classes from './Title.css'

type Props = {
  size: '1' | '2' | '3' | '4',
  children: React.Node,
  className?: string,
}

export default class Title extends React.Component<Props> {
  render() {
    const { size, className, children, ...props } = this.props
    return (
      <div
        {...props}
        className={cx(classes.container, classes[`size-${size}`], className)}
      >
        {children}
      </div>
    )
  }
}
