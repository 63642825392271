// @flow

import actionTypes from './actionTypes'

type Config = {
  type: 'read' | 'create' | 'update' | 'delete',
  resourceType: string,
  request: Function,
  resources?: Array<Object>,
  requestKey: string,
  requestName: string,
  dispatch: Function,
}

export const resourceAction = (config: Config) => {
  if (!config) return null
  const {
    type,
    resourceType,
    // resources,
    request,
    requestKey,
    requestName = requestKey,
    dispatch,
  } = config

  const actionType = actionTypes[type]

  if (!actionType) {
    throw new Error(
      `redux-resource-request: request type ${type} for ${resourceType}.${requestName}() is invalid. Use read, create, update or delete`,
    )
  }

  // TODO: local actions
  // request can be synchronous, without server request
  // let localAction

  // if (resources) {
  //   localAction = dispatch({
  //     type: actionType.local,
  //     resources: {
  //       [resourceType]: resources,
  //     },
  //   })
  // }

  // if (typeof request !== 'function') {
  //   return localAction
  // }

  dispatch({
    type: actionType.pending,
    resourceType,
    requestKey,
    requestName,
  })

  return request()
    .then(response => {
      return dispatch({
        // response must include resources, and can include requestProperties, list, meta
        ...response,
        type: actionType.succeeded,
        resourceType,
        requestKey,
        requestName,
      })
    })
    .catch(error => {
      dispatch({
        type: actionType.failed,
        resourceType,
        requestKey,
        requestName,
      })

      throw error
    })
}
