export default {
  resourceType: 'countries',
  relations: {
    competitions: {
      type: 'hasMany',
      model: 'competitions',
      foreignKey: 'countryId',
    },
  },
}
