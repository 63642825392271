import { resourceReducer } from 'redux-resource'
import { includedResources } from 'redux-resource-plugins'
import * as resources from '../resources/resources'
import user from './user'

const reducersFromResources = {}

Object.keys(resources).forEach(key => {
  reducersFromResources[key] = resourceReducer(key, {
    plugins: [includedResources],
  })
})

export default {
  user,
  ...reducersFromResources,
}
