// @flow
import React from 'react'
import { Field } from 'react-form'
import FieldForm from 'components/Form/Fields/Field'
import Select from 'components/Form/Fields/Select'
import styles from 'styles'
import Message from '../Message.jsx'
import classes from './Select.css'

type Props = {
  field: string,
  validators: Object,
  label: string,

  // native props
  value?: string,
  onChange?: Function,
  validators?: Object,
  validate?: Function,
  fieldStyle?: Object,
  fieldClassName?: string,
}

type State = {}

export default class SelectForm extends React.Component<Props, State> {
  render() {
    const {
      value: defaultValue,
      onChange,
      label,
      validators,
      field,
      validate,
      fieldStyle,
      fieldClassName,
      ...props
    } = this.props
    return (
      <Field validate={validate} field={field}>
        {fieldApi => {
          const { value, error, warning, success, setValue } = fieldApi
          return (
            <FieldForm
              label={label}
              style={{ paddingBottom: error && 0, ...fieldStyle }}
              className={fieldStyle}
            >
              <div className={classes.container}>
                <Select
                  {...props}
                  // value={value || ''}
                  optionIsSelected={option => option.value === value}
                  onChange={({ value }, event) => {
                    setValue(value)
                    if (onChange) {
                      onChange(value, event)
                    }
                  }}
                />
                {error ? (
                  <Message color={styles.colors.red} message={error} />
                ) : null}
                {!error && warning ? (
                  <Message color="orange" message={warning} />
                ) : null}
                {!error && !warning && success ? (
                  <Message color="green" message={success} />
                ) : null}
              </div>
            </FieldForm>
          )
        }}
      </Field>
    )
  }
}
