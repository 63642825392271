// @flow
import * as React from 'react'
import cx from 'classnames'
import classes from './Field.css'

type Props = {
  label: string,
  children: React.Node,
}

export default class Field extends React.Component<Props> {
  render() {
    const { label, children, ...props } = this.props
    return (
      <div
        {...props}
        className={cx(classes.container, { [classes.withoutLabel]: !label })}
      >
        {label && <div className={classes.label}>{label}</div>}
        <div className={classes.fieldValue}>{children}</div>
      </div>
    )
  }
}
