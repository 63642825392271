// @flow
import * as React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { css } from 'emotion'
import styles, { type ColorType } from 'styles'
import darken from 'styles/darken'
import Loader from 'components/Loader'
import classes from './Button.css'

export const icons = {
  arrowDown: 'fas fa-angle-down',
  arrowRight: 'fas fa-angle-right',
  trash: 'fas fa-trash',
  close: 'fas fa-times',
}

type Props = {
  isLoading: boolean,
  className?: string,
  color: ColorType,
  disabled: boolean,
  type: string,
  children?: React.Node,
  iconFA?: $Values<typeof icons>,

  /**
   * generate a link <a href="" />, like <Link to="" />
   */
  to?: string,
  onClick?: Function,
}

export default class Button extends React.Component<Props> {
  static defaultProps = {
    type: 'button',
    isLoading: false,
    color: 'mainColor',
    disabled: false,
  }

  onClick = (event: Object) => {
    const { onClick, isLoading, disabled } = this.props
    if (!isLoading && onClick && !disabled) {
      onClick(event)
    }
  }

  render() {
    const {
      isLoading,
      type,
      disabled,
      to,
      className,
      children,
      iconFA,
      ...props
    } = this.props
    let { color } = this.props

    color = disabled ? 'grey' : color

    const containerStyle = css`
      background-color: ${styles.colors[color]};
      :hover {
        background-color: ${darken(styles.colors[color])};
      }
    `

    const isLoadingStyle = css`
      padding-right: ${isLoading && 0};
    `

    const icon = iconFA && (
      <span className={iconFA} style={{ paddingRight: children && 10 }} />
    )

    if (to) {
      return (
        <Link
          {...props}
          to={to}
          className={cx(classes.container, containerStyle, className)}
        >
          <div>
            {icon}
            {children}
          </div>
        </Link>
      )
    }

    return (
      <button
        {...props}
        type={type}
        className={cx(
          classes.container,
          containerStyle,
          isLoadingStyle,
          iconFA && classes.isIcon,
          className,
        )}
        onClick={this.onClick}
      >
        <div>
          {icon}
          {children}
          {isLoading && (
            <div className={classes.loader}>
              <Loader size="small" />
            </div>
          )}
        </div>
      </button>
    )
  }
}
