// @flow
import { pick, pickBy, isPlainObject, mapValues, find, drop, filter as lodashFilter } from 'lodash'
import { type ResourceType } from 'core/types'
import * as resources from 'store/resources/resources'

type Filter = string | Array<string> | Object | Function
type IncludedResources = Array<string>

export const getResources = (
  state: Object,
  resourceType: ResourceType,
  filter?: Filter,
  includedResources?: IncludedResources,
) => {
  const resourceConfig = resources[resourceType]
  if (!resourceConfig) {
    throw new Error(`getResources: resourceType ${resourceType} doesn't exist`)
  }

  const resourcesSlice = state[resourceType].resources

  let filteredResources = resourcesSlice

  let filterIsId = false

  if (Array.isArray(filter)) {
    filteredResources = pick(resourcesSlice, filter)
  } else if (isPlainObject(filter) || typeof filter === 'function') {
    filteredResources = pickBy(resourcesSlice, filter)
  } else if (typeof filter === 'string') {
    filterIsId = true
    filteredResources = resourcesSlice[filter]
  }

  if (!includedResources) {
    return filteredResources
  }

  if (!resourceConfig.relations) {
    throw new Error(`getResources: resourceType ${resourceType} has no relations object`)
  }

  includedResources.forEach(resourcePath => {
    const splitedPath = resourcePath.split('.')
    const relationPropertyName = splitedPath[0]
    const restIncludedResources = splitedPath[1] ? [drop(splitedPath).join('.')] : undefined

    const relationPropertyConfig = resourceConfig.relations[relationPropertyName]
    if (!relationPropertyConfig) {
      throw new Error(`getResources: resourceType ${resourceType} has no relation ${relationPropertyName}`)
    }

    if (relationPropertyConfig.type === 'hasOne') {
      const relationResourcesSlice = state[relationPropertyConfig.model].resources

      const getOneResource = resource => {
        const { id } = resource
        const relatedResource = find(relationResourcesSlice, {
          [relationPropertyConfig.foreignKey]: id,
        })

        return {
          ...resource,
          [relationPropertyName]:
            relatedResource &&
            getResources(state, relationPropertyConfig.model, relatedResource.id, restIncludedResources),
        }
      }

      filteredResources = filterIsId ? getOneResource(filteredResources) : mapValues(filteredResources, getOneResource)
    } else if (relationPropertyConfig.type === 'hasMany') {
      const relationResourcesSlice = state[relationPropertyConfig.model].resources

      const getOneResource = resource => {
        const { id } = resource
        const relatedResources = lodashFilter(relationResourcesSlice, {
          [relationPropertyConfig.foreignKey]: id,
        }).map(({ id }) => id)

        return {
          ...resource,
          [relationPropertyName]: getResources(
            state,
            relationPropertyConfig.model,
            relatedResources,
            restIncludedResources,
          ),
        }
      }

      filteredResources = filterIsId ? getOneResource(filteredResources) : mapValues(filteredResources, getOneResource)
    }
  })

  return filteredResources
}

export const getResourcesFromList = (
  state: Object,
  resourceType: ResourceType,
  listName: string,
  includedResources?: IncludedResources,
) => {
  const ids = state[resourceType].lists[listName]
  if (!ids) {
    throw new Error(`getResourcesFromList: list ${listName} of ${resourceType} doesn't exist`)
  }
  return getResources(state, resourceType, ids, includedResources)
}

export const getResourcesFromRequest = (
  state: Object,
  resourceType: ResourceType,
  requestName: string,
  includedResources?: IncludedResources,
) => {
  const request = state[resourceType].requests[requestName]

  if (!request) {
    return {}
    // throw new Error(
    //   `getResourcesFromList: request '${requestName}' of ${resourceType} doesn't exist`,
    // )
  }

  const { ids } = request

  return getResources(state, resourceType, ids, includedResources)
}
