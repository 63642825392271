// @flow
import * as React from 'react'
import { mapValues } from 'lodash'
import type { ResourcesList, Match } from 'core/types'
import pipe from 'core/utils/pipe'
import api from 'core/api'
import ReactTable from 'components/ReactTable'
import { Text } from 'components/ReactTable/Cells'
import classes from './Matches.css'

type Props = {
  matches: ResourcesList<Match>,
}

type State = {}

class Matches extends React.Component<Props, State> {
  state = {}

  requestMatches = () => {
    // api.matches().providersRequest()
    api.matches().providersApiDashFootball()
  }

  updateMatches = () => {
    api.matches().updateMatches()
  }

  render() {
    const { matches } = this.props
    return (
      <div className={classes.container}>
        {/* <Button onClick={this.requestMatches} className={classes.requestButton}>
          Request matches
        </Button>
        <Button onClick={this.updateMatches} className={classes.requestButton}>
          Update matches
        </Button> */}

        <div className={classes.content}>
          <ReactTable
            data={Object.values(matches)}
            defaultSorted={[{ id: 'date', desc: true }]}
            columns={[
              Text({
                Header: 'Date',
                // accessor: 'date',
                id: 'date',
                accessor: item => new Date(item.date).toLocaleString(),
                // Cell: row => {
                //   return <div>{new Date(row.value).toLocaleString()}</div>
                // },
                filterable: true,
              }),
              Text({
                Header: 'HomeTeam',
                accessor: 'homeTeam.name',
                filterable: true,
              }),
              Text({
                Header: 'AwayTeam',
                accessor: 'awayTeam.name',
                filterable: true,
              }),
              Text({
                Header: 'Stadium',
                id: 'stadium',
                accessor: match => {
                  if (!match.stadium) return ''
                  return `${match.stadium.name} (${match.stadium.city})`
                },
                filterable: true,
              }),
            ]}
          />
        </div>
      </div>
    )
  }
}

const getMatches = state => {
  return mapValues(state.matches.resources, match => ({
    ...match,
    stadium: state.stadiums.resources[match.stadiumId],
    homeTeam: state.teams.resources[match.homeTeamId],
    awayTeam: state.teams.resources[match.awayTeamId],
  }))
}

export default pipe()
  .resources()
  .connect((state, props) => ({
    countries: state.countries.resources,
    countryId: props.match.params.countryId,
    matches: getMatches(state),
  }))
  .request(props => {
    return Promise.all([
      props.resources.countries().fetch(),
      props.resources.teams().fetch(),
      props.resources.stadiums().fetch(),
      props.resources.matches().fetch({
        // params: {
        //   filter: {
        //     include: [
        //       {
        //         relation: 'homeTeam',
        //       },
        //       {
        //         relation: 'awayTeam',
        //       },
        //     ],
        //   },
        // },
      }),
    ])
  })
  // .mapRequestToProps(res => ({ apiFootballCountries: res[1] }))
  .render(Matches)
