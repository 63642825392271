// @flow

import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalTrigger } from 'components/Modal'
import TextRead from '../Common/TextRead/TextRead.jsx'
import { ReactTableContext } from '../../ReactTable.jsx'
import classes from './Text.css'

export default ({ onChange, ModalEdit, ...data }: Object = {}) => ({
  ...data,
  Cell: (row: Object) => {
    return (
      <ReactTableContext.Consumer>
        {context => {
          if (!context) return null
          const { getValue, showEdit, onChange } = context.rowInfos(row, data)
          return showEdit() ? (
            <input
              value={getValue() || ''}
              onChange={({ target: { value } }) => onChange(value)}
              className={classes.input}
            />
          ) : (
            <TextRead>
              {row.value}
              {ModalEdit && (
                <ModalTrigger modal={ModalEdit(row)}>
                  <div className={classes.iconEdit}>
                    <FontAwesomeIcon icon="edit" className={classes.iconEdit} />
                  </div>
                </ModalTrigger>
              )}
            </TextRead>
          )
        }}
      </ReactTableContext.Consumer>
    )
  },
})
