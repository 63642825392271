// @flow
import React from 'react'
import { Field } from 'react-form'
import validator from 'validator'
import cx from 'classnames'
import { colors } from 'styles/colors'
import FieldForm from 'components/Form/Fields/Field'
import Input from 'components/Form/Fields/Input'
import Message from '../Message.jsx'
import classes from './Input.css'

const validatorsLabel = {
  isEmail: value => {
    return !validator.isEmail(value) && 'Email invalide'
  },
  isRequired: value => {
    return !value && 'Champs requis'
  },
}

const validate = (validators?: Object) => (value: string = '') => {
  if (!validators) return null
  if (!value && !validators.isRequired) return null

  // eslint-disable-next-line
  for (let [validatorKey, validatorValue] of Object.entries(validators)) {
    if (validatorValue === true || typeof validatorValue === 'string') {
      if (!validatorsLabel[validatorKey]) {
        throw new Error(`Validator error: validator '${validatorKey}' is not valid`)
      }

      const defaultError = validatorsLabel[validatorKey](value)
      if (defaultError) {
        return {
          error: typeof validatorValue === 'string' ? validatorValue : defaultError,
        }
      }
    } else if (typeof validatorValue === 'function') {
      return validatorValue(value)
    }
  }
  return null
}

type Props = {
  field: string,
  validators: Object,
  label?: string,

  // native props
  value?: string,
  type?: string,
  onBlur?: Function,
  onChange?: Function,
  className?: string,
  validators?: Object,
  fieldStyle?: Object,
  fieldClassName?: string,
}

export default class InputForm extends React.Component<Props> {
  static defaultProps = {
    type: 'text',
  }

  validate = () => {
    return validate(this.props.validators)
  }

  render() {
    const {
      value: defaultValue,
      onBlur,
      onChange,
      label,
      validators,
      className,
      field,
      fieldStyle,
      fieldClassName,
      ...props
    } = this.props
    return (
      <Field
        validate={this.validate()}
        field={field}
        // preValidate={false}
      >
        {fieldApi => {
          const { value, error, warning, success, setValue, setTouched } = fieldApi
          return (
            <FieldForm label={label} style={{ paddingBottom: error && 0, ...fieldStyle }} className={fieldClassName}>
              <div className={classes.container}>
                <Input
                  {...props}
                  className={cx(error && classes.error, className)}
                  value={value === undefined || value === null ? '' : value}
                  onChange={e => {
                    setValue(e.target.value)
                    if (onChange) {
                      onChange(e.target.value, e)
                    }
                  }}
                  onBlur={e => {
                    setTouched()
                    if (onBlur) {
                      onBlur(e)
                    }
                  }}
                />
                {error ? <Message color={colors.red} message={error} /> : null}
                {!error && warning ? <Message color="orange" message={warning} /> : null}
                {!error && !warning && success ? <Message color="green" message={success} /> : null}
              </div>
            </FieldForm>
          )
        }}
      </Field>
    )
  }
}
