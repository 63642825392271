// @flow
import { getAxiosConfig } from './getAxiosConfig'

type Data = Object | Array<Object>

export const fetch = ({ resourceAction, apiResource, transformResult }: Object) => {
  return () => (config: Object = {}) => (dispatch: Function) => {
    return resourceAction({
      dispatch,
      type: 'read',
      request: () =>
        apiResource()
          .fetch(getAxiosConfig(config))
          .then(transformResult),
    })
  }
}

export const create = ({ resourceAction, apiResource, transformResult }: Object) => {
  return (data: Data) => (config: Object = {}) => (dispatch: Function) => {
    return resourceAction({
      dispatch,
      type: 'read',
      request: () =>
        apiResource(data)
          .create(getAxiosConfig(config))
          .then(transformResult),
    })
  }
}

export const update = ({ resourceAction, apiResource, transformResult }: Object) => {
  return (data: Data) => (config: Object = {}) => (dispatch: Function) => {
    return resourceAction({
      dispatch,
      type: 'update',
      request: () =>
        apiResource(data)
          .update(getAxiosConfig(config))
          .then(transformResult),
    })
  }
}
