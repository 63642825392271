// @flow

type SettingsType = {
  env: Object,
  authTokenKey: string,
}

const settings: SettingsType = {
  env: Object.freeze(window.AWAY_ENV), // avoid someone to override the settings in the browser console
  authTokenKey: 'awayfootball_auth',
}

export default settings
