// @flow
import type { AxiosRestSchema } from 'utils/axios-rest/types'

const resources: AxiosRestSchema = {
  resources: {
    accessTokens: {
      uri: 'accessTokens',
    },
    competitions: {
      uri: 'competitions',
      actions: {
        count: {
          uri: 'count',
          method: 'GET',
        },
      },
    },
    teamConnectors: {
      uri: 'teamConnectors',
    },
    matchConnectors: {
      uri: 'matchConnectors',
    },
    competitionConnectors: {
      uri: 'competitionConnectors',
    },
    countries: {
      uri: 'countries',
      actions: {
        count: {
          uri: 'count',
          method: 'GET',
        },
      },
    },
    matches: {
      uri: 'matches',
      actions: {
        providersApiDashFootball: {
          uri: 'providersApiDashFootball',
          method: 'POST',
        },
        updateMatches: {
          uri: 'updateMatches',
          method: 'POST',
        },
      },
    },
    providersADFLeagues: {
      uri: 'providerApiDashFootballLeagues',
      actions: {
        fetchOrigin: {
          uri: 'fetchOrigin',
          method: 'GET',
        },
      },
    },
    stadiums: {
      uri: 'stadiums',
      actions: {
        count: {
          uri: 'count',
          method: 'GET',
        },
      },
    },
    teams: {
      uri: 'teams',
      actions: {
        count: {
          uri: 'count',
          method: 'GET',
        },
        presignedURL: {
          uri: 'presigned-url',
          method: 'GET',
        },
      },
    },
    users: {
      uri: 'users',
      actions: {
        login: {
          uri: 'login',
          method: 'POST',
        },
        logout: {
          uri: 'logout',
          method: 'POST',
        },
        changePassword: {
          uri: 'change-password',
          method: 'POST',
        },
      },
    },
  },
  actions: {},
}

export default resources
