import React from 'react'
import classes from './Style.css'

export default class LoginLayout extends React.Component {
  render() {
    return (
      <div className={classes.layout}>
        <div className={classes.formContainer}>{this.props.children}</div>
      </div>
    )
  }
}
