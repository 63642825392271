// @flow

import * as React from 'react'
import cx from 'classnames'
import { get, set, cloneDeep } from 'lodash'
import ReactTableDefault from 'react-table'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import 'react-table/react-table.css'
import classes from './ReactTable.css'

type Props = Object

type State = {
  rowsBeingEdit: Object,
}

export const ReactTableContext = React.createContext()

const ReactTable = withFixedColumns(ReactTableDefault)

export default class ReactTableBase extends React.PureComponent<Props, State> {
  state = {
    rowsBeingEdit: {},
  }

  onChangeField = (rowId: string, columnId: string, value: any) => {
    this.setState(state => ({
      rowsBeingEdit: {
        ...state.rowsBeingEdit,
        [rowId]: {
          ...state.rowsBeingEdit[rowId],
          [columnId]: value,
        },
      },
    }))
  }

  startRowEdit = (rowId: string) => {
    this.setState(state => ({
      rowsBeingEdit: {
        ...state.rowsBeingEdit,
        [rowId]: this.props.data.find(({ id }) => id === rowId),
      },
    }))
  }

  endRowEdit = (rowId: string) => {
    this.setState(state => ({
      rowsBeingEdit: {
        ...state.rowsBeingEdit,
        [rowId]: null,
      },
    }))
  }

  save = (rowId: string) => {
    const { data, onSave } = this.props
    const { rowsBeingEdit } = this.state

    const original = data.find(({ id }) => id === rowId)
    const newOriginal = rowsBeingEdit[rowId]

    const output = {}

    Object.entries(newOriginal).forEach(([key, value]) => {
      if (JSON.stringify(value) !== JSON.stringify(original[key])) {
        output[key] = value
      }
    })

    const needSave = !!Object.keys(output).length
    output.id = rowId

    return (needSave ? onSave(output) : Promise.resolve()).then(() => {
      this.endRowEdit(rowId)
    })
  }

  rowInfos = (row: Object, data: Object) => {
    const { rowsBeingEdit } = this.state
    const rowId = row.original.id
    const { enableEdit, accessorEdit, accessor } = data

    return {
      getValue: () => {
        let value

        if (accessorEdit) {
          if (typeof accessorEdit === 'string') {
            return get(rowsBeingEdit[rowId], accessorEdit, '')
          } else if (typeof accessorEdit === 'function') {
            return accessorEdit(rowsBeingEdit[rowId])
          }
        }

        if (typeof accessor === 'string') {
          return get(rowsBeingEdit[rowId], accessor, '')
        } else if (typeof accessor === 'function') {
          return accessor(rowsBeingEdit[rowId])
        }

        return value
      },
      showEdit: () => enableEdit && !!rowsBeingEdit[rowId],
      onChange: (value: any) => {
        this.setState(state => {
          return {
            rowsBeingEdit: {
              ...state.rowsBeingEdit,
              [rowId]: set(cloneDeep(state.rowsBeingEdit[rowId]), accessorEdit || accessor, value),
            },
          }
        })
      },
    }
  }

  render() {
    const { rowsBeingEdit } = this.state
    return (
      <ReactTableContext.Provider
        value={{
          rowsBeingEdit,
          startRowEdit: this.startRowEdit,
          endRowEdit: this.endRowEdit,
          save: this.save,
          rowInfos: this.rowInfos,
        }}
      >
        <ReactTable
          minRows={0}
          defaultPageSize={50}
          defaultFilterMethod={(filter, row) => {
            if (!row[filter.id]) return null
            return row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
          }}
          {...this.props}
          className={cx(classes.reactTable, '-striped')}
        />
      </ReactTableContext.Provider>
    )
  }
}
