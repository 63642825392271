import cookies from 'js-cookie'
import settings from '../settings/index'

export const getAuthHeaders = () => {
  const token = cookies.get(settings.authTokenKey)
  if (!token) return null
  return {
    Authorization: token,
  }
}

const getHeaders = () => ({
  ...getAuthHeaders(),
})

export default getHeaders
