// @flow
import * as React from 'react'
import ReactTable from 'components/ReactTable'
import { Edit, Text } from 'components/ReactTable/Cells'
import type { ResourcesList, Country } from 'core/types'
import pipe from 'core/utils/pipe'
import { getCountriesFromLocal } from 'core/providers/apiFootball'
import classes from './Countries.css'

type Props = {
  countries: ResourcesList<Country>,
  resources: Object,
  countryId: string,
}

type State = {}

class Countries extends React.Component<Props, State> {
  state = {}

  onSubmitProviders = providers => {
    const { resources, countryId } = this.props
    return resources
      .countries({
        id: countryId,
        providers,
      })
      .update()
  }

  onSave = data => {
    return this.props.resources.countries(data).update()
  }

  render() {
    const { countries } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <ReactTable
            data={Object.values(countries)}
            defaultSorted={[{ id: 'name', desc: false }]}
            onSave={this.onSave}
            columns={[
              Edit(),
              Text({
                Header: 'name',
                accessor: 'name',
                filterable: true,
                enableEdit: true,
              }),
            ]}
          />
        </div>
      </div>
    )
  }
}

export default pipe()
  .resources()
  .connect((state, props) => ({
    countries: state.countries.resources,
    countryId: props.match.params.countryId,
  }))
  .request(props => {
    return Promise.all([props.resources.countries().fetch(), getCountriesFromLocal()])
  })
  .mapRequestToProps(res => ({ apiFootballCountries: res[1] }))
  .render(Countries)
