// @flow
import React, { useCallback } from 'react'
import useForm from 'react-hook-form'
import styled from 'styled-components'
import { ModalConfirm } from 'components/Modal'
import { Input, Field, Select } from 'components/Form/Fields'
import { useResources } from 'store/useStore'
// import { GoogleMapsAutocomplete } from './GoogleMapAutocomplete'

const ErrorMessage = styled.div`
  color: #f00;
`

type Props = {
  countries: any,
}

// function useInitGoogleMapsAutocomplete(data) {
//   useEffect(() => {
//     // eslint-disable-next-line
//     const googleMapsAutocomplete = new GoogleMapsAutocomplete({
//       stadium: data.stadium,
//       onChange: data.onChange,
//       $input: document.querySelector('#input-address'),
//     })

//     googleMapsAutocomplete.init()
//   }, [])
// }

export function ModalCreateStadium(props: Props) {
  const { countries, ...restProps } = props
  const { register, triggerValidation, getValues, setValue, errors } = useForm()
  const resources = useResources()

  const onValidate = useCallback(async () => {
    if (!(await triggerValidation())) {
      return false
    }

    const values = getValues()

    const countryProperties = {
      name: values.name,
      city: values.city,
      addressFull: values.addressFull,
      pos: {
        lat: parseFloat(values.lat, 10),
        lng: parseFloat(values.lng, 10),
      },
      countryId: values.countryId,
    }

    await resources.stadiums(countryProperties).create()

    return true
  })

  return (
    <ModalConfirm title="New stadium" onValidate={onValidate} {...restProps}>
      <Field label="Name">
        <Input name="name" innerRef={register({ required: true })} />
        <ErrorMessage>{errors.name && errors.name.type}</ErrorMessage>
      </Field>
      <Field label="City">
        <Input name="city" innerRef={register({ required: true })} />
        <ErrorMessage>{errors.city && errors.city.type}</ErrorMessage>
      </Field>
      <Field label="Full Address">
        <Input name="addressFull" innerRef={register({ required: true })} />
        <ErrorMessage>{errors.addressFull && errors.addressFull.type}</ErrorMessage>
      </Field>
      <Field label="Latitude (ex: 51.5548885)">
        <Input name="lat" innerRef={register({ required: true })} />
        <ErrorMessage>{errors.lat && errors.lat.type}</ErrorMessage>
      </Field>
      <Field label="Longitude (ex: -0.108438)">
        <Input name="lng" innerRef={register({ required: true })} />
        <ErrorMessage>{errors.lng && errors.lng.type}</ErrorMessage>
      </Field>
      {/* <Field label="Full Address">
        <Input name="address" innerRef={register({ required: true })} />
        <ErrorMessage>{errors.address && errors.address.type}</ErrorMessage>
      </Field> */}
      {/* <Field label="Address">
        <Input id="input-address" placeholder="search address with google" />
        <div id="address-gmap" />
      </Field> */}
      <Field label="Country">
        <input name="countryId" ref={register({ required: true })} style={{ display: 'none' }} />
        <Select
          name="countryId"
          onChange={value => setValue('countryId', value.value)}
          innerRef={register}
          options={Object.values(countries).map(country => ({
            label: country.name,
            value: country.id,
          }))}
        />
        <ErrorMessage>{errors.countryId && errors.countryId.type}</ErrorMessage>
      </Field>
    </ModalConfirm>
  )
}
