import React from 'react'
import PropTypes from 'prop-types'
import { mapValues } from 'lodash'
import { connect } from 'react-redux'
import { pipeRequest } from 'react-hoc-pipe'
import Loader from 'components/Loader'
import resources from 'store/resources'

const hocs = {
  resources: {
    HOC: () => () => App => {
      // eslint-disable-next-line
      return class HOCResources extends React.Component<void> {
        static contextTypes = {
          store: PropTypes.object,
        }
        render() {
          const resourcesBinded = mapValues(resources, resource => {
            return data =>
              mapValues(resource(data), action => {
                return config => action(config)(this.context.store.dispatch)
              })
          })

          return <App {...this.props} resources={resourcesBinded} />
        }
      }
    },
  },
  connect: {
    HOC: () => connect,
  },
}

export default () => pipeRequest(hocs).renderLoader(() => <Loader />)
