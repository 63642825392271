import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import epics from './actions/epics.js'
import reducers from './reducers/index.js'

const reduxDevToolsCompose =
  // eslint-disable-next-line
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ serialize: true })
const composeEnhancers = reduxDevToolsCompose || compose

const epicMiddleware = createEpicMiddleware(epics)

const enhancer = composeEnhancers(applyMiddleware(epicMiddleware))

const createReducers = _reducers => {
  return combineReducers(_reducers)
}

const store = createStore(createReducers(reducers), enhancer)

export default store
