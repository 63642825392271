// @flow
import React from 'react'
import cx from 'classnames'
import classes from './Input.css'

type Props = {
  className?: string,
  onChange?: Function,
  type?: string,
  shiftStep?: number,
  innerRef: any,
}

const UP_KEYCODE = 38
const DOWN_KEYCODE = 40

export default class Input extends React.PureComponent<Props> {
  nextValue: ?number = null
  onChange = (event: Object) => {
    const { onChange } = this.props
    const { target } = event
    if (onChange) {
      if (this.nextValue) {
        onChange({
          ...event.nativeEvent,
          target: { ...target, value: this.nextValue },
        })
      } else {
        onChange(event)
      }
    }
  }

  onKeyDown = (event: Object) => {
    const { type, shiftStep } = this.props
    const { keyCode, shiftKey, target } = event
    this.nextValue = null

    if (shiftStep && type && type === 'number' && shiftKey) {
      const numberValue = Number(target.value)
      if (keyCode === UP_KEYCODE) {
        this.nextValue = Math.round((numberValue + shiftStep) * 100) / 100
      } else if (keyCode === DOWN_KEYCODE) {
        this.nextValue = Math.round((numberValue - shiftStep) * 100) / 100
      }
    }
  }

  render() {
    const { className, shiftStep, innerRef, ...props } = this.props
    return (
      <input
        ref={innerRef}
        {...props}
        className={cx(classes.input, className)}
        onKeyDown={this.onKeyDown}
        onChange={this.onChange}
      />
    )
  }
}
