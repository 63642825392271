// @flow

import * as React from 'react'
import ReactDOM from 'react-dom'
import Draggable from 'react-draggable'
import cx from 'classnames'
import { css } from 'emotion'
import uniqid from 'uniqid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { colors } from 'styles/colors'
import classes from './Modal.css'

const contentId = uniqid()
const modalRoot = document.getElementById('app-modal')

export type Props = {
  // onAfterOpen: Function,
  onRequestClose?: Function,
  onRequestCloseRef?: Function,
  // shouldCloseOnOverlayClick: boolean,
  onKeyUpEnter?: Function,
  title: string,
  titleColor: string,
  overflow?: string,
  minWidth?: number,
  showCloseBtn?: boolean,
  draggable?: boolean,
  children: React.Node | string,
}

type State = {
  top: number,
  left: number,
}

export const defaultProps = {
  shouldCloseOnOverlayClick: false,
  overflow: 'auto',
  minWidth: 400,
  showCloseBtn: true,
  titleColor: 'blackNew',
  draggable: false,
}

export default class Modal extends React.PureComponent<Props, State> {
  static defaultProps = defaultProps

  static currentModalId = []

  state = {
    top: 0,
    left: 0,
  }

  id: string = uniqid()

  containerRef: ?HTMLElement

  el: HTMLElement

  constructor(props: Props) {
    super(props)
    this.constructor.currentModalId.push(this.id)
    this.el = document.createElement('div')
    if (props.onRequestCloseRef) {
      props.onRequestCloseRef(this.onRequestClose)
    }
  }

  componentDidMount() {
    const { draggable } = this.props
    window.addEventListener('keyup', this.onKeyUp)

    if (modalRoot) {
      modalRoot.appendChild(this.el)
    }

    if (draggable && this.containerRef && document.body) {
      const { offsetHeight, offsetWidth } = document.body
      this.setState({
        top: (offsetHeight - this.containerRef.offsetHeight) / 2,
        left: (offsetWidth - this.containerRef.offsetWidth) / 2,
      })
    }
  }

  componentWillUnmount() {
    const index = this.constructor.currentModalId.findIndex(
      id => id === this.id,
    )
    this.constructor.currentModalId.splice(index, 1)
    window.removeEventListener('keyup', this.onKeyUp)
    if (modalRoot) {
      modalRoot.removeChild(this.el)
    }
  }

  onKeyUp = (event: Object) => {
    if (this.isLastModalOpened()) {
      if (event.keyCode === 13) {
        const { onKeyUpEnter } = this.props
        if (onKeyUpEnter) onKeyUpEnter()
      }
    }
  }

  onRequestClose = () => {
    // console.log('Modal onRequestClose')
    const { onRequestClose } = this.props
    if (onRequestClose) onRequestClose()
    // if (modalRoot) {
    //   modalRoot.removeChild(this.el)
    // }
  }

  isLastModalOpened() {
    const { length } = this.constructor.currentModalId
    return length && this.constructor.currentModalId[length - 1] === this.id
  }

  renderTitle() {
    const { title, titleColor, draggable } = this.props

    if (!title) return null

    const titleStyle = css`
      background-color: ${colors[titleColor]};
      cursor: ${draggable ? 'move' : 'normal'};
    `

    return <div className={cx([classes.title, titleStyle])}>{title}</div>
  }

  renderCloseBtn() {
    const { showCloseBtn, title } = this.props
    if (!showCloseBtn) return null

    return (
      <div className={classes.close} onClick={this.onRequestClose}>
        <FontAwesomeIcon
          icon="close"
          style={{ color: title ? '#fff' : '#000' }}
        />
      </div>
    )
  }

  render() {
    const {
      // onAfterOpen,
      // shouldCloseOnOverlayClick,
      overflow,
      children,
      title,
      draggable,
    } = this.props

    const contentClassName = cx(classes.content, {
      hasTitle: !!title,
    })

    const content = (
      <div className={classes.innerModal}>
        {this.renderTitle()}
        {this.renderCloseBtn()}
        <div
          style={{ overflow }}
          id="contentId"
          onMouseDown={event => {
            event.stopPropagation()
          }}
        >
          <div className={contentClassName}>{children}</div>
        </div>
      </div>
    )

    const commonProps = {
      onClick: event => {
        // prevent bubble event because modal is insert inside other component with React.Portal
        event.stopPropagation()
      },
    }

    const { top, left } = this.state
    const output = draggable ? (
      <Draggable cancel={`#${contentId}`}>
        <div
          ref={c => {
            this.containerRef = c
          }}
          {...commonProps}
          style={{
            backgroundColor: '#fff',
            position: 'fixed',
            top,
            left,
            bottom: 'auto',
            right: 'auto',
            padding: '0px',
            maxHeight: 'calc(100% - 50px)',
            display: 'flex',
            flexDirection: 'column',
            border: 'none',
            overflow,
            minWidth: this.props.minWidth,
            borderRadius: 4,
            boxShadow: '4px 4px 15px 4px #888',
          }}
        >
          {content}
        </div>
      </Draggable>
    ) : (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            position: 'relative',
            top: 'auto',
            left: 'auto',
            bottom: 'auto',
            right: 'auto',
            padding: '0px',
            maxHeight: 'calc(100% - 50px)',
            display: 'flex',
            flexDirection: 'column',
            border: 'none',
            overflow,
            minWidth: this.props.minWidth,
            borderRadius: 4,
          }}
          {...commonProps}
        >
          {content}
        </div>
      </div>
    )

    return ReactDOM.createPortal(output, this.el)
  }
}
