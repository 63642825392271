import React from 'react'
// import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom'
import { Layout, Login, Logout, Register } from 'pages/Login'

export default class login extends React.Component {
  // static propTypes = {
  //   match: PropTypes.object.isRequired,
  // }

  render() {
    // const { match } = this.props;
    return (
      <Layout>
        <Switch>
          <Route path="/auth/register" component={Register} />
          <Route path="/auth/login" component={Login} />
          <Route path="/auth/logout" component={Logout} />
        </Switch>
      </Layout>
    )
  }
}
