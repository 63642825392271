// @flow
import { get, set } from 'lodash'

export const createResource = (getLocalStorageKey: Function) => {
  const getData = (itemPath?: string, defaultValue?: any): Object => {
    const strData = localStorage.getItem(getLocalStorageKey()) || ''
    let data
    try {
      data = JSON.parse(strData) || {}
    } catch (e) {
      data = {}
    }

    if (itemPath) {
      return get(data, itemPath, defaultValue)
    }

    return data
  }

  const setData = (itemPath: string, value: any) => {
    const data = getData()
    const nextData = set(data, itemPath, value)
    localStorage.setItem(getLocalStorageKey(), JSON.stringify(nextData))
  }

  return {
    getData,
    setData,
  }
}
