// @flow

import * as React from 'react'
import type { ColorType } from 'styles/colors'
import Button from 'components/Buttons/Button'
import Modal, {
  type Props as ModalProps,
  defaultProps as defaultPropsModal,
} from './Modal.jsx'
import classes from './ModalConfirm.css'

export const ModalFormContext = React.createContext()

export type Props = ModalProps & {
  validate: boolean,
  cancel: boolean,
  validateLabel: string,
  cancelLabel: string,
  validateLabelColor: ColorType,
  cancelLabelColor: ColorType,

  /**
   * @return {bool|Promise}
   */
  onValidate?: Function,
  onCancel?: Function,
  onRequestClose?: Function,
}

type State = {
  isLoading: boolean,
}

export const defaultProps = {
  validate: true,
  cancel: true,
  validateLabel: 'Valider',
  cancelLabel: 'Annuler',
  validateLabelColor: 'green',
  cancelLabelColor: 'grey',
  titleColor: 'mainColor',
}

export default class ModalConfirm extends React.PureComponent<Props, State> {
  static defaultProps = {
    ...defaultPropsModal,
    ...defaultProps,
  }

  static defaultProps = defaultProps

  state = {
    isLoading: false,
  }

  childrenRef: React.Node = null

  customRefs: Object = {}

  onRequestClose: Function

  onValidate = () => {
    const { onValidate } = this.props

    if (!onValidate) return

    this.setState({ isLoading: true })

    const validate = onValidate(this.customRefs)

    if (validate === true) {
      this.onRequestClose()
    } else if (validate && validate.then) {
      validate
        .then(res => {
          if (typeof res === 'string') {
            this.onError(res)
            this.setState({ isLoading: false })
          } else if (res === false) {
            this.setState({ isLoading: false })
          } else {
            this.onRequestClose()
          }
        })
        .catch(error => {
          this.setState({ isLoading: false })
          throw error
        })
    } else if (typeof validate === 'string') {
      this.onError(validate)
    } else {
      console.warn(
        'ModalConfirm onValidate() must return a boolean or a Promise',
      )
    }
  }

  onCancel = () => {
    const { onCancel } = this.props
    if (onCancel) onCancel()
    this.onRequestClose()
  }

  // eslint-disable-next-line
  onError(error: string) {
    // notif({ error });
    this.setState({ isLoading: false })
  }

  onRequestClose() {
    const { onRequestClose } = this.props
    if (onRequestClose) {
      onRequestClose()
    }
  }

  getRefs = (refKey: string) => {
    return (c: React.Node) => {
      this.customRefs[refKey] = c
    }
  }

  render() {
    const {
      // modal props
      title,
      draggable,
      onRequestClose,

      // modal confirm props
      children,
      validate,
      validateLabel,
      validateLabelColor,
      cancel,
      cancelLabel,
      cancelLabelColor,
      ...props
    } = this.props
    return (
      <Modal
        title={title}
        draggable={draggable}
        onRequestClose={onRequestClose}
        showCloseBtn={false}
        {...props}
      >
        {children}
        <div className={classes.btns}>
          {cancel && (
            <Button
              onClick={this.onCancel}
              color={cancelLabelColor}
              style={{ flex: 1, marginRight: validate && 20 }}
            >
              {cancelLabel}
            </Button>
          )}
          {validate && (
            <Button
              onClick={this.onValidate}
              isLoading={this.state.isLoading}
              color={validateLabelColor}
              style={{ flex: 1 }}
            >
              {validateLabel}
            </Button>
          )}
        </div>
      </Modal>
    )
  }
}
