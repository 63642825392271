// @flow

export function ObjectValues<Obj: Object>(o: Obj): Array<$Values<Obj>> {
  return Object.values(o)
}

export function ObjectEntries<T>(obj: { [string]: T }): Array<[string, T]> {
  const keys: string[] = Object.keys(obj)
  return keys.map(key => [key, obj[key]])
}

export default ObjectValues
