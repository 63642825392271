// @flow
import * as React from 'react'
import { Form as ReactForm } from 'react-form'
import Button from 'components/Buttons/Button'
import { ModalFormContext } from 'components/Modal/ModalConfirmForm.jsx'
import Message from './Message.jsx'
import classes from './Form.css'

type Props = {
  // rect-form props
  onSubmit: ?Function,
  render: Function,
  getApi?: Function,
  onSubmitFailure?: Function,

  submitButtonProps?: Object,
  submitButtonLabel?: React.Node,
  defaultValidateButton?: boolean,
  style?: Object,
}

type State = {
  // isLoading: boolean,
  errorMessage?: string,
}

export default class Form extends React.Component<Props, State> {
  static defaultProps = {
    submitButtonLabel: 'Valider',
    defaultValidateButton: true,
  }

  state = {
    // isLoading: false,
  }

  contextValue: Object

  onSubmit = (...args: any) => {
    const { onSubmit } = this.props

    if (!onSubmit) return null

    // this.setState({ isLoading: true });
    return (
      onSubmit(...args)
        .then(res => {
          if (this.contextValue) {
            this.contextValue.onSubmited(res)
          }
          return res
        })
        // .then(e => console.log(e))
        .catch(error => {
          // if it's a server request
          if (error && error.response && error.response.data) {
            const { data } = error.response
            if (data.error && data.error.message) {
              this.setState({ errorMessage: data.error.message })
              return null
            }
          } else {
            this.setState({ errorMessage: 'Erreur' })
          }

          throw new Error(error)
        })
    )
  }

  onSubmitFailure = (...args: Array<any>) => {
    const { onSubmitFailure } = this.props
    if (this.contextValue) {
      this.contextValue.onSubmitFailure(...args)
    }

    if (onSubmitFailure) {
      return onSubmitFailure(...args)
    }

    throw args[1]
  }

  getApi = (api: any) => {
    const { getApi } = this.props
    if (getApi) {
      getApi(api)
    }

    if (this.contextValue) {
      this.contextValue.getApi(api)
    }
  }

  render() {
    const {
      onSubmit,
      render,
      submitButtonProps,
      submitButtonLabel,
      defaultValidateButton,
      style,
      getApi,
      ...props
    } = this.props
    const { errorMessage } = this.state

    return (
      <ModalFormContext.Consumer>
        {contextValue => {
          if (contextValue) {
            this.contextValue = contextValue
          }

          return (
            <ReactForm
              {...props}
              getApi={this.getApi}
              onSubmit={this.onSubmit}
              onSubmitFailure={this.onSubmitFailure}
              validateOnSubmit
              render={apiForm => {
                const { submitForm, submitting } = apiForm
                const validateButtonEl = (
                  <Button
                    type="submit"
                    color="green"
                    isLoading={submitting}
                    className={classes.submitButton}
                    {...submitButtonProps}
                  >
                    {submitButtonLabel}
                  </Button>
                )

                return (
                  <form onSubmit={submitForm} style={style}>
                    {errorMessage &&
                      !submitting && <Message>{errorMessage}</Message>}
                    {render(apiForm, validateButtonEl)}
                    {!contextValue &&
                      defaultValidateButton && (
                        <div className={classes.btns}>{validateButtonEl}</div>
                      )}
                  </form>
                )
              }}
            />
          )
        }}
      </ModalFormContext.Consumer>
    )
  }
}
