// @flow
module.exports = {
  styles: {
    colors: {
      blue: '#273345',
      green: '#3ab652',
      grey: '#c7c8cb',
      // mainColor: '#909ba3',
      mainColor: '#38485B',
      orange: '#f77e1d',
      or: '#bb9d54',
      orLight: 'rgb(204, 185, 129)',
      red: '#ee4d53',
    },
    mainFont: 'ralewayregular',
    mainFontBold: 'ralewaybold',
    mainBorder: 'solid 1px #c7c8cb',
    mainBorderRadius: '5p',
  },
}
