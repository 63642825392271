export default {
  resourceType: 'competitions',
  relations: {
    connector: {
      type: 'hasOne',
      model: 'competitionConnectors',
      foreignKey: 'competitionId',
    },
  },
}
