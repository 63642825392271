// @flow

import { mapValues } from 'lodash'
import { resourceAction as resourceActionBase } from 'utils/redux-resource-request'
import api from 'core/api'
import * as resources from '../resources'
import { getIncludedResources } from './getIncludedResources'
import { getReduxResourceRequestConfig } from './getReduxResourceRequestConfig'
import * as CRUDActions from './CRUDActions'

export const createResources = () =>
  mapValues(resources, (resourceConfig, resourceType) => {
    const apiResourceType = resourceConfig.resourceType
    const apiResource = apiResourceType && api[apiResourceType]

    if (!apiResource) {
      throw new Error(`api resource ${apiResourceType} is undefined`)
    }

    const actions = {
      ...CRUDActions,
      ...resourceConfig.actions,
    }

    return data => {
      // return actions like fetch, create...
      return mapValues(actions, (action, actionName) => {
        // catch config and inject it into resourceAction() or redux-resource-request
        return config => {
          const resourceAction = (params: Object) => {
            return resourceActionBase({
              requestKey: actionName,
              ...getReduxResourceRequestConfig(config), // automatically inject config
              ...params, // config define in action setup
              requestName: actionName, // requestName can't be override
              resourceType,
            })
          }

          const transformResult = ({ data }) => {
            const resourcesAsArray = Array.isArray(data) ? data : [data]
            return {
              resources: resourcesAsArray,
              includedResources: getIncludedResources(resourceType, resourcesAsArray),
            }
          }

          return action({
            apiResource,
            resourceAction,
            transformResult,
          })(data)(config)
        }
      })
    }
  })
