// @flow

import * as React from 'react'
import { map, find, filter } from 'lodash'
import Fuse from 'fuse.js'
import pipe from 'core/utils/pipe'
import { getResourcesFromRequest } from 'store/resources/utils/getResources__NEW'
import { ObjectValues } from 'utils/flowPolyfills'
import ReactTable from 'components/ReactTable'
import { Edit, Text, Select } from 'components/ReactTable/Cells'
import type { ResourcesList, Team, TeamConnector } from 'core/types'
import classes from './UncompletedTeams.css'

type Props = {
  teamConnectors: ResourcesList<TeamConnector>,
  teamConnectorsCompleted: ResourcesList<TeamConnector>,
  teams: ResourcesList<Team>,
  resources: Object,
}

export class UncompletedTeamsBase extends React.PureComponent<Props> {
  updateConnector = (row: Object, teamId: string) => {
    const { resources } = this.props
    const connectorId = row.original.id
    const connector = {
      id: connectorId,
      teamId,
    }

    resources.teamConnectors(connector).update()
  }

  onSave = data => {
    const { resources } = this.props
    const connector = {
      id: data.id,
      teamId: data.teamId,
    }

    return resources.teamConnectors(connector).update()
  }

  render() {
    const { teamConnectors, teamConnectorsCompleted, teams } = this.props
    const teamsAsArray = filter(
      teams,
      team => !find(teamConnectorsCompleted, connector => connector.teamId === team.id),
    )

    const teamsData = map(teamConnectors, connector => {
      if (connector.providerName === 'api-football.com') {
        const match = connector.plainData
        let providerTeamName
        if (match.homeTeam_id === connector.providerTeamId) {
          providerTeamName = match.homeTeam
        } else if (match.awayTeam_id === connector.providerTeamId) {
          providerTeamName = match.awayTeam
        } else {
          throw new Error('Error connector team_id')
        }

        const fuse = new Fuse(teamsAsArray, {
          shouldSort: true,
          threshold: 0.2,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 3,
          keys: ['name'],
        })

        const options = fuse.search(providerTeamName)

        return {
          id: connector.id,
          connector,
          providerTeamName,
          options,
          provider: 'api-football',
        }
      }

      return {}
    })

    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <ReactTable
            data={teamsData}
            onSave={this.onSave}
            columns={[
              Edit(),
              Text({
                Header: 'Provider',
                accessor: 'provider',
                maxWidth: 150,
              }),
              Text({
                Header: 'Provider team Name',
                accessor: 'providerTeamName',
              }),
              Select({
                Header: 'AwayFootball team',
                id: 'teamId',
                accessor: item => {
                  const team = teams[item.connector.teamId]
                  return team && team.name
                },
                accessorEdit: 'teamId',
                options: ObjectValues(teams)
                  // exclude team with existing connector
                  .filter(team => !find(teamConnectorsCompleted, connector => connector.teamId === team.id))
                  .map(team => ({
                    value: team.id,
                    label: `${team.name} - ${team.country.name} ${
                      !team.stadium || !team.stadium.pos ? '(no stadium)' : ''
                    }`,
                  })),
                filterable: true,
                enableEdit: true,
              }),
              {
                Header: 'AwayFootball team - Suggestions',
                accessor: 'options',
                Cell: row => (
                  <div className={classes.suggestions}>
                    {row.value.map(({ id, stadium, name, country }) => (
                      <div key={id} className={classes.suggestionItem} onClick={() => this.updateConnector(row, id)}>
                        {name} - {country.name}
                        {(!stadium || !stadium.pos) && <span style={{ color: 'red' }}>(no stadium)</span>}
                      </div>
                    ))}
                  </div>
                ),
              },
              // Text({
              //   Header: 'Api-Football',
              //   accessor: 'providers.apifootball.name',
              //   width: 200,
              //   ModalEdit: row => {
              //     const { id, providers } = row.original
              //     return (
              //       <ModalConfirmForm title="Edit">
              //         <Form
              //           // values={}
              //           onSubmit={({ name }) => {
              //             if (!name) return Promise.resolve(true)
              //             const { resources } = this.props
              //             const teamData = {
              //               id,
              //               providers: {
              //                 ...providers,
              //                 apifootball: { name },
              //               },
              //             }
              //             return resources.teams.update(teamData)
              //           }}
              //           render={() => (
              //             <div>
              //               <Field label="Select league">
              //                 <SelectField
              //                   options={apiFootballLeagues.map(league => ({
              //                     value: league.league_id,
              //                     label: league.league_name,
              //                     countryName: league.country_name,
              //                   }))}
              //                   onChange={this.onChangeApiFootballLeagues}
              //                   formatOptionLabel={({ label, countryName }) => {
              //                     return (
              //                       <div>
              //                         {label} &nbsp; ({countryName})
              //                       </div>
              //                     )
              //                   }}
              //                 />
              //               </Field>
              //               <SelectForm
              //                 key={apiFootballLeagueId}
              //                 label="Select team"
              //                 field="name"
              //                 options={standings.map(team => ({
              //                   value: team.team_name,
              //                   label: team.team_name,
              //                 }))}
              //               />
              //             </div>
              //           )}
              //         />
              //       </ModalConfirmForm>
              //     )
              //   },
              // }),
            ]}
          />
        </div>
      </div>
    )
  }
}

export const UncompletedTeams = pipe()
  .resources()
  .connect(state => ({
    teamConnectorsCompleted: getResourcesFromRequest(state, 'teamConnectors', 'fetchAllCompleted'),
    teamConnectors: getResourcesFromRequest(state, 'teamConnectors', 'fetchAllUncompleted'),
    teams: state.teams.resources,
  }))
  .request(({ resources }) => {
    return Promise.all([
      resources.teamConnectors().fetch({
        requestKey: 'fetchAllUncompleted',
        params: {
          filter: {
            where: {
              teamId: null,
            },
          },
        },
      }),
      resources.teamConnectors().fetch({
        requestKey: 'fetchAllCompleted',
        params: {
          filter: {
            where: {
              teamId: { neq: null },
            },
          },
        },
      }),
      resources.teams().fetch({
        params: {
          filter: {
            include: [{ relation: 'stadium' }, { relation: 'country' }],
          },
        },
      }),
    ])
  })
  .render(UncompletedTeamsBase)

export default UncompletedTeams
