import api from 'core/api'
import cookies from 'js-cookie'
import settings from 'core/settings'
import redirectToLogin from 'routes/redirectToLogin'
import { Observable } from 'rxjs'

const FETCH_USER = Symbol('FETCH_USER')
const START_LOGOUT = Symbol('START_LOGOUT')

// epics types
export const FETCH_USER_SUCCESS = Symbol('FETCH_USER_SUCCESS')
export const LOGOUT_SUCCESS = Symbol('LOGOUT_SUCCESS')

export const fetchUserFromToken = () => ({ type: FETCH_USER })

export const logout = () => ({ type: START_LOGOUT })

/* ________________________________________________
 *
 *                   EPICS
 * ________________________________________________
 */
export const fetchUserFromTokenEpic = action$ =>
  action$.ofType(FETCH_USER).mergeMap(() => {
    const token = cookies.get(settings.authTokenKey)
    if (!token) {
      redirectToLogin()
      return Observable.empty()
    }
    return Observable.from(
      api
        .accessTokens(token)
        .fetch()
        .then(res => {
          const { userId } = res.data
          return api.users(userId).fetch()
        })
        .catch(error => {
          console.error(error)
          return null
        }),
    ).map(payload => {
      return { type: FETCH_USER_SUCCESS, user: payload ? payload.data : null }
    })
  })

export const logoutEpic = action$ =>
  action$.ofType(START_LOGOUT).mergeMap(() =>
    Observable.from(
      api
        .users()
        .logout()
        .then(() => {
          cookies.set(settings.authTokenKey, '')
          redirectToLogin()
        }),
    ).map(() => ({ type: LOGOUT_SUCCESS })),
  )

export const epics = {
  fetchUserFromTokenEpic,
  logoutEpic,
}
