// @flow
import React from 'react'
import PubSub from 'pubsub-js'
import uniqid from 'uniqid'
import ModalConfirm from './ModalConfirm.jsx'

const openModalEventName = uniqid()

type State = {
  modals: Array<any>,
}

export default class DefaultConfirm extends React.Component<void, State> {
  state = {
    modals: [],
  }

  componentDidMount() {
    PubSub.subscribe(openModalEventName, (msg, data) => {
      const { render, ...modalProps } = data

      const modalId = uniqid()

      const onRequestClose = () => this.closeModal(modalId)

      const children = typeof render === 'function' ? render() : render

      const modal = {
        id: modalId,
        element: (
          <ModalConfirm
            {...modalProps}
            key={modalId}
            onRequestClose={onRequestClose}
          >
            {children}
          </ModalConfirm>
        ),
      }

      this.setState(state => ({
        modals: [...state.modals, modal],
      }))
    })
  }

  closeModal(modalId: string) {
    this.setState(state => ({
      modals: state.modals.filter(({ id }) => id !== modalId),
    }))
  }

  render() {
    return <div>{this.state.modals.map(({ element }) => element)}</div>
  }
}

export const confirm = (data: any) => {
  return new Promise((resolve, reject) => {
    PubSub.publish(openModalEventName, {
      ...data,
      onValidate: (...args) => {
        const onValidateOutput = data.onValidate(...args)
        resolve(onValidateOutput)
        return onValidateOutput
      },
      onCancel: (...args) => {
        const onCancelOutput = data.onCancel(...args)
        reject(onCancelOutput)
        return onCancelOutput
      },
    })
  })
}
