// @flow
import * as React from 'react'
import ModalConfirm, {
  type Props as PropsModalConfirm,
  defaultProps as defaultPropsConfirm,
} from './ModalConfirm.jsx'

export const ModalFormContext = React.createContext()

type Props = PropsModalConfirm & {}

export default class ModalConfirmForm extends React.PureComponent<Props> {
  static defaultProps = defaultPropsConfirm

  formApi: Object

  resolve: Function

  reject: Function

  onValidate = () => {
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.formApi.submitForm()
    })
  }

  onSubmited = (res: any) => {
    this.resolve(res)
  }

  onSubmitFailure = (errors: any) => {
    if (this.reject) {
      this.reject(errors)
    }
  }

  render() {
    return (
      <ModalFormContext.Provider
        value={{
          getApi: formApi => {
            this.formApi = formApi
          },
          onSubmited: this.onSubmited,
          onSubmitFailure: this.onSubmitFailure,
        }}
      >
        <ModalConfirm
          draggable={true}
          {...this.props}
          onValidate={this.onValidate}
        />
      </ModalFormContext.Provider>
    )
  }
}
