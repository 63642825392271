import { FETCH_USER_SUCCESS, LOGOUT_SUCCESS } from '../actions/user'

export default (state = null, action) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return action.user

    case LOGOUT_SUCCESS:
      return null

    default:
      return state
  }
}
