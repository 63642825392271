// @flow
import { normalize, schema } from 'normalizr'
import { mapValues } from 'lodash'
import * as resourcesAPI from '../resources'

const getSchema = (resourceType: string, path: Object = {}): Object => {
  const resourceAPI = resourcesAPI[resourceType]
  if (!resourceAPI) {
    const error = `redux resource ${resourceType} doesn't exist`
    console.error(error)
    throw new Error(error)
  }

  const relations = resourceAPI.relations || {}
  return new schema.Entity(
    resourceType,
    mapValues(relations, (relation, key) => {
      const keyForPathHistory = `${relation.model}__${key}`

      if (path[keyForPathHistory]) {
        // console.warn(`infinite loop into getSchema for ${relatedresourceType}`)
        return null
      }

      if (relation.type === 'hasOne') {
        const entity = getSchema(relation.model, {
          ...path,
          [keyForPathHistory]: true,
        })
        return entity
      }

      return null
    }),
  )
}

export const getIncludedResources = (resourceType: string, data: Array<Object>): Object => {
  const resourceAPI = resourcesAPI[resourceType]
  if (!resourceAPI.relations) return {}

  const schema = getSchema(resourceType)
  const { entities } = normalize(data, [schema])

  return entities
}
