import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import AuthenticatedLayout from 'components/Layouts/Authenticated.jsx'
import Dashboard from 'pages/Dashboard'
import Teams from 'pages/Teams'
import Competitions from 'pages/Competitions'
import Countries from 'pages/Countries'
import UncompletedTeams from 'pages/UncompletedTeams'
import Matches from 'pages/Matches'
import Stadiums from 'pages/Stadiums'
// import NotFound from 'pages/NotFound';

export default () => (
  <AuthenticatedLayout>
    <Switch>
      <Route path="(/|/dashboard)" component={Dashboard} />
      <Route path="/teams/:countryId?/:teamId?" component={Teams} />
      <Route path="/uncompleted-teams/" component={UncompletedTeams} />
      <Route path="/competitions/:competitionId?" component={Competitions} />
      <Route path="/countries/:countryId?" component={Countries} />
      <Route path="/matches/" component={Matches} />
      <Route path="/stadiums/" component={Stadiums} />

      <Redirect to="/dashboard" />
      {/* <Route path="*" component={NotFound} /> */}
    </Switch>
  </AuthenticatedLayout>
)
