// @flow
import React, { useCallback } from 'react'
import useForm from 'react-hook-form'
import styled from 'styled-components'
import { ModalConfirm } from 'components/Modal'
import { Input, Field, Select } from 'components/Form/Fields'
import { useResources } from 'store/useStore'

const ErrorMessage = styled.div`
  color: #f00;
`

type Props = {
  countries: any,
}

export function ModalCreateTeam(props: Props) {
  const { countries, ...restProps } = props
  const { register, triggerValidation, getValues, setValue, errors } = useForm()
  const resources = useResources()

  const onValidate = useCallback(async () => {
    if (!(await triggerValidation())) {
      return false
    }

    const teamProperties = getValues()

    await resources.teams(teamProperties).create()

    return true
  })

  return (
    <ModalConfirm title="New team" onValidate={onValidate} {...restProps}>
      <Field label="Name">
        <Input name="name" innerRef={register({ required: true })} />
        <ErrorMessage>{errors.name && errors.name.type}</ErrorMessage>
      </Field>
      <Field label="Short name">
        <Input name="shortName" innerRef={register} />
      </Field>
      <Field label="Website">
        <Input name="website" innerRef={register} />
      </Field>
      <Field label="Country">
        <input name="countryId" ref={register({ required: true })} style={{ display: 'none' }} />
        <Select
          name="countryId"
          onChange={value => setValue('countryId', value.value)}
          innerRef={register}
          options={Object.values(countries).map(country => ({
            label: country.name,
            value: country.id,
          }))}
        />
        <ErrorMessage>{errors.countryId && errors.countryId.type}</ErrorMessage>
      </Field>
    </ModalConfirm>
  )
}
