// @flow

import * as React from 'react'
// $FlowFixMe
import Select from 'react-select'
import TextRead from '../Common/TextRead/TextRead.jsx'
import { ReactTableContext } from '../../ReactTable.jsx'
// import classes from './Select.css'

const getSelectedOption = (options, value) => options.find(opt => opt.value === value) || {}

export default ({ onChange, options, ...data }: Object = {}) => ({
  ...data,
  Cell: (row: Object) => {
    return (
      <ReactTableContext.Consumer>
        {context => {
          if (!context) return null

          const { getValue, showEdit, onChange } = context.rowInfos(row, data)

          if (showEdit()) {
            const finalOptions = typeof options === 'function' ? options(row) : options
            const selectedOption = getSelectedOption(finalOptions, getValue())
            // return (
            //   <select
            //     value={selectedOption.value}
            //     onChange={({ target: { value } }) => onChange(value)}
            //   >
            //     {options.map(opt => (
            //       <option key={opt.value} value={opt.value}>
            //         {opt.label}
            //       </option>
            //     ))}
            //   </select>
            // )
            return (
              <Select
                value={selectedOption || {}}
                onChange={({ value }) => {
                  onChange(value)
                }}
                options={finalOptions}
                menuPortalTarget={document.getElementById('select-portal')}
              />
            )
          }

          // const selectedOption = getSelectedOption(finalOptions, row.value)
          // return <TextRead>{selectedOption.label}</TextRead>
          return <TextRead>{row.value}</TextRead>
        }}
      </ReactTableContext.Consumer>
    )
  },
})
