// @flow
import { confirm } from './DefaultConfirm.jsx'

export default (data: Object) => {
  return confirm({
    title: 'Suppression',
    titleColor: 'red',
    validateLabelColor: 'red',
    validateLabel: 'Supprimer',
    ...data,
  })
}
