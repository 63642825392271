// @flow
import * as React from 'react'
import ReactTable from 'components/ReactTable'
import { Edit, Text, Select } from 'components/ReactTable/Cells'
import Button from 'components/Buttons/Button'
import { ModalTrigger } from 'components/Modal'
import type { Country, Stadium } from 'core/types'
import { ObjectValues } from 'utils/flowPolyfills'
import pipe from 'core/utils/pipe'
import classes from './Stadiums.css'
import { ModalCreateStadium } from './ModalCreateStadium.jsx'

type Props = {
  countries: { [countryId: string]: Country },
  stadiums: { [stadiumId: string]: Stadium },
  resources: Object,
}

type State = {
  standings: Array<Object>,
  apiFootballLeagueId: ?string,
}

class Stadiums extends React.Component<Props, State> {
  onSave = data => {
    return this.props.resources.stadiums(data).update()
  }

  render() {
    const { countries, stadiums } = this.props

    return (
      <div className={classes.container}>
        <div className={classes.buttonContainer}>
          <ModalTrigger modal={<ModalCreateStadium countries={countries} />}>
            <Button>Create Stadium</Button>
          </ModalTrigger>
        </div>
        <div className={classes.content}>
          <ReactTable
            data={Object.values(stadiums)}
            onSave={this.onSave}
            columns={[
              {
                Header: '',
                fixed: 'left',
                columns: [
                  Edit(),
                  Text({
                    Header: 'Name',
                    accessor: 'name',
                    filterable: true,
                    enableEdit: true,
                    width: 250,
                  }),
                ],
              },
              Text({
                Header: 'City',
                accessor: 'city',
                filterable: true,
                width: 200,
              }),
              Text({
                Header: 'Address',
                accessor: 'addressFull',
                enableEdit: true,
                filterable: true,
              }),
              Text({
                Header: 'Lat',
                accessor: 'pos.lat',
                enableEdit: true,
              }),
              Text({
                Header: 'Lng',
                accessor: 'pos.lng',
                enableEdit: true,
              }),
              Select({
                Header: 'Country',
                id: 'countryId',
                accessor: item => countries[item.countryId].name,
                accessorEdit: 'countryId',
                options: ObjectValues(countries).map(country => ({
                  value: country.id,
                  label: country.name,
                })),
                filterable: true,
                width: 200,
              }),
            ]}
          />
        </div>
      </div>
    )
  }
}

export default pipe()
  .resources()
  .connect(state => ({
    countries: state.countries.resources,
    stadiums: state.stadiums.resources,
  }))
  .request(({ resources }) => {
    return Promise.all([resources.countries().fetch(), resources.stadiums().fetch()])
  })
  .render(Stadiums)
