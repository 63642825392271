// @flow
import axios from 'axios'
import settings from 'core/settings'
import cookies from 'js-cookie'
// import createAPI from 'utils/axios-rest/createAPI'
import createAxiosRest from '@guillaumejasmin/axios-rest'
import redirectToLogin from 'routes/redirectToLogin'
import getHeaders from './getHeaders'
import schema from './resources'

const axiosInst = axios.create({
  baseURL: settings.env.apiURL,
  timeout: 20000,
})

axiosInst.interceptors.request.use(
  config => {
    const token = cookies.get(settings.authTokenKey)
    if (token) {
      // eslint-disable-next-line
      config.headers = {
        ...config.headers,
        ...getHeaders(),
      }
    }
    return config
  },
  err => {
    return Promise.reject(err)
  },
)

axiosInst.interceptors.response.use(
  response => {
    return response
  },
  error => {
    switch (error.response && error.response.status) {
      case 401: {
        const { pathname } = window.location
        if (!pathname.startsWith('/auth/')) {
          redirectToLogin()
        }
        break
      }
      default:
        break
    }
    return Promise.reject(error)
  },
)

export default createAxiosRest(axiosInst, schema)
