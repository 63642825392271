// @flow
import * as React from 'react'

type Props = {
  children: React.Element<any>,
  modal: React.Element<any>,
}

type State = {
  modalIsOpen: boolean,
}

export default class Button extends React.Component<Props, State> {
  state = {
    modalIsOpen: false,
  }

  render() {
    const { children, modal } = this.props
    const { modalIsOpen } = this.state

    return (
      <React.Fragment>
        {React.cloneElement(children, {
          onClick: () => {
            this.setState({ modalIsOpen: true })
          },
        })}
        {modalIsOpen &&
          React.cloneElement(modal, {
            onRequestClose: () => {
              this.setState({ modalIsOpen: false })
            },
          })}
      </React.Fragment>
    )
  }
}
