// @flow
import React from 'react'
import { connect } from 'react-redux'
import cookies from 'js-cookie'
import queryString from 'query-string'
import api from 'core/api'
import settings from 'core/settings'
import history from 'routes/history'
import Form from 'components/Form/ReactForm/Form/Form.jsx'
import Input from 'components/Form/ReactForm/Input/Input.jsx'
// import classes from './Style.css';

type Props = {
  user?: Object,
}

class Login extends React.PureComponent<Props, {}> {
  static onSubmit({ email, password }) {
    return api
      .users()
      .login({ email, password })
      .then(res => {
        cookies.set(settings.authTokenKey, res.data.id)
        const { redirect = '/' } = queryString.parse(window.location.search)
        history.push(redirect)
      })
  }

  componentDidMount() {
    // check if user is already logged
    const token = cookies.get(settings.authTokenKey)
    if (token) {
      api
        .accessTokens(token)
        .fetch()
        .then(({ data }) => {
          if (data && data.userId) {
            history.push('/')
          }
        })
        .catch(() => null)
    }
  }

  checkUser() {
    if (this.props.user) {
      history.push('/')
    }
  }

  handleField(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render() {
    return (
      <Form
        onSubmit={Login.onSubmit}
        defaultValidateButton={false}
        validateOnSubmit={true}
        submitButtonLabel="Login"
        render={(api, validateButton) => (
          <div>
            <Input
              field="email"
              placeholder="Email"
              validators={{
                isRequired: 'hop hop hop, email requis !',
                isEmail: true,
              }}
            />
            <Input
              type="password"
              field="password"
              placeholder="Password"
              validators={{ isRequired: true }}
            />
            {React.cloneElement(validateButton, { style: { width: '100%' } })}
          </div>
        )}
      />
    )
  }
}

export default connect(state => ({ user: state.user }))(Login)
