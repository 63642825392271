// @flow
import React from 'react'
import api from 'core/api'
import { ObjectEntries } from 'utils/flowPolyfills'
import pipe from 'core/utils/pipe'
import classes from './Dashboard.css'

type Props = {
  teamsCount: number,
  stadiumsCount: number,
  countriesCount: number,
  competitionsCount: number,
}

type State = {
  // bar?: boolean,
}

// eslint-disable-next-line
class Dashboard extends React.Component<Props, State> {
  // static defaultProps = {};

  // constructor (props) {
  //   super(props);
  //   this.state = {};
  // }

  componentDidMount() {
    // resources.teams.fetch(undefined, undefined, undefined, store.dispatch)
  }

  // shouldComponentUpdate (nextProps, nextState) {}

  // componentDidUpdate (prevProps, prevState) {}

  // componentWillUnmount () {}

  render() {
    const { teamsCount, stadiumsCount, countriesCount, competitionsCount } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.itemsList}>
          {ObjectEntries({
            Teams: teamsCount,
            Stadiums: stadiumsCount,
            Countries: countriesCount,
            Competitions: competitionsCount,
          }).map(([text, value]) => (
            <div key={text} className={classes.item}>
              <div className={classes.numberValue}>{value}</div>
              <div>{text}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default pipe()
  .resources()
  .request(props => {
    return Promise.all([
      api
        .teams()
        .count()
        .then(({ data }) => data.count),
      api
        .stadiums()
        .count()
        .then(({ data }) => data.count),
      api
        .countries()
        .count()
        .then(({ data }) => data.count),
      api
        .competitions()
        .count()
        .then(({ data }) => data.count),
      props.resources.teams().fetch(),
    ]).then(([teamsCount, stadiumsCount, countriesCount, competitionsCount]) => ({
      teamsCount,
      stadiumsCount,
      countriesCount,
      competitionsCount,
    }))
  })
  .mapRequestToProps(data => data)
  .render(Dashboard)
