// @flow
import settings from 'core/settings'
import axios from 'axios'
import { createResource } from 'utils/localStorageResource'

export const actions = {
  get_leagues: 'get_leagues',
  get_countries: 'get_countries',
  get_standings: 'get_standings',
  get_events: 'get_events',
  get_odds: 'get_odds',
}

export type ActionsType = $Keys<typeof actions>

export const request = (action: ActionsType, params: Object = {}) => {
  return axios.get('https://apifootball.com/api/', {
    params: {
      ...params,
      action,
      APIkey: settings.env.providers.apiFootballApiKey,
    },
  })
}

const localStorageResource = createResource(() => 'apiFootballData')

// $FlowFixMe
const lastRequestDateStr: string = localStorageResource.getData('lastDataClear')
const hoursIntervals = 24
if (
  !lastRequestDateStr ||
  new Date() - new Date(lastRequestDateStr) > 1000 * 60 * 60 * hoursIntervals
) {
  localStorage.removeItem('apiFootballData')
  localStorageResource.setData('lastDataClear', new Date().toISOString())
}

// countries
export const getCountries = () => request(actions.get_countries)
export const getCountriesFromLocal = () => {
  return new Promise(resolve => {
    const data = localStorageResource.getData('countries')
    if (data) {
      resolve(data)
    } else {
      getCountries()
        .then(({ data }) => localStorageResource.setData('countries', data))
        .then(() => resolve(localStorageResource.getData('countries')))
    }
  })
}

// leagues
export const getLeagues = (countryId?: string) => {
  return request(actions.get_leagues, { country_id: countryId || null })
}

export const getLeaguesFromLocal = () => {
  return new Promise(resolve => {
    const data = localStorageResource.getData('leagues')
    if (data) {
      resolve(data)
    } else {
      getLeagues()
        .then(({ data }) => localStorageResource.setData('leagues', data))
        .then(() => resolve(localStorageResource.getData('leagues')))
    }
  })
}

export const getStandings = (leagueId: string) => {
  return request(actions.get_standings, { league_id: leagueId })
}

export const getStandingsFromLocal = (leagueId: string): Object => {
  const key = `standings.league-${leagueId}`
  return new Promise(resolve => {
    const data = localStorageResource.getData(key)
    if (data) {
      resolve(data)
    } else {
      getStandings(leagueId)
        .then(({ data }) => localStorageResource.setData(key, data))
        .then(() => resolve(localStorageResource.getData(key)))
    }
  })
}
