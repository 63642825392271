import history from './history'

export default () => {
  const { pathname, search } = window.location
  let query = ''
  if (!pathname.startsWith('/auth/')) {
    const redirectTo = `${pathname}${search}`
    query = redirectTo ? `?redirect=${redirectTo}` : ''
  }

  history.push(`/auth/login${query}`)
}
