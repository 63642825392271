// @flow
import * as React from 'react'
import cx from 'classnames'
// $FlowFixMe
import ReactSelect from 'react-select'
import classes from './Select.css'

type Props = {
  className?: string,
  containerProps?: Object,
  value?: any,
  options: Array<Object>,
  optionIsSelected?: Function,
  innerRef: any,
}

type State = {
  value?: Object,
}

export default class Select extends React.PureComponent<Props, State> {
  static getDerivedStateFromProps(props: Props) {
    if (props.optionIsSelected) {
      const option = props.options.find(props.optionIsSelected)
      if (option) {
        return { value: option }
      }
    }

    return { value: props.value }
  }

  state = {}

  render() {
    const { containerProps, optionIsSelected, innerRef, ...props } = this.props

    const nextClassName = cx(classes.container, containerProps && containerProps.className)

    return (
      <div className={nextClassName} {...containerProps}>
        <ReactSelect
          ref={innerRef}
          {...props}
          value={this.state.value}
          menuPortalTarget={document.getElementById('select-portal')}
        />
      </div>
    )
  }
}
