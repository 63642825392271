import React from 'react'
import { DefaultConfirm } from 'components/Modal'
import classes from './Main.css'

export default class MainLayout extends React.Component {
  componentDidMount() {
    // execute code on the first load page
  }
  render() {
    return (
      <div className={classes.container}>
        {this.props.children}
        <DefaultConfirm />
      </div>
    )
  }
}
