// @flow
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import store from 'store'
// import { Dropdown, DropdownItem } from 'components/Dropdown'
import { fetchUserFromToken } from 'store/actions/user'
import classes from './Authenticated.css'

type Props = {
  user: Object,
  children: React.Node,
}

const NavItem = ({ children, ...props }) => (
  <NavLink {...props} className={classes.navItem} activeClassName={classes.active}>
    {children}
  </NavLink>
)

class AuthenticatedLayout extends React.Component<Props> {
  componentDidMount() {
    store.dispatch(fetchUserFromToken())
  }

  onClick = () => {}

  render() {
    const { user, children } = this.props
    if (!user) return <Loader />
    return (
      <div className={classes.container}>
        <div className={classes.leftNavContainer}>
          <NavItem to="/dashboard">Dashboard</NavItem>
          <NavItem to="/teams">Teams</NavItem>
          <NavItem to="/uncompleted-teams">Uncompleted Teams</NavItem>
          <NavItem to="/competitions">Competitions</NavItem>
          <NavItem to="/countries">Countries</NavItem>
          <NavItem to="/stadiums">Stadiums</NavItem>
          <NavItem to="/matches">Matches</NavItem>
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    )
  }
}

export default connect(state => ({ user: state.user }), { fetchUserFromToken })(AuthenticatedLayout)
