import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'rxjs'
import 'styles/global'
import store from 'store'
import { HookStoreProvider } from './store/useStore'
import routes from './routes/Routes.jsx'

const App = () => (
  <HookStoreProvider store={store}>
    <Provider store={store}>{routes()}</Provider>
  </HookStoreProvider>
)

ReactDOM.render(<App />, document.getElementById('app'))
