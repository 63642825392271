import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import MainLayout from 'components/Layouts/Main.jsx'
import Admin from './Admin.jsx'
import Login from './Login.jsx'
import history from './history'

export default () => (
  <Router history={history}>
    <MainLayout>
      <Switch>
        <Route path="/auth" component={Login} />
        <Route path="/" component={Admin} />
      </Switch>
    </MainLayout>
  </Router>
)
