// @flow
import * as React from 'react'
import { find } from 'lodash'
import history from 'routes/history'
import pipe from 'core/utils/pipe'
import { type ResourcesList, type Country, type Competition, type CompetitionConnector } from 'core/types'
import ReactTable from 'components/ReactTable'
import { Edit, Text, Select } from 'components/ReactTable/Cells'
import { getResources, getResourcesFromRequest } from 'store/resources/utils/getResources'
import { ObjectValues } from 'utils/flowPolyfills'
import classes from './Competitions.css'

type Props = {
  countries: ResourcesList<Country>,
  competitions: ResourcesList<Competition>,
  resources: Object,
  competitionId: string,
  competitionConnectors: ResourcesList<CompetitionConnector>,
}

type State = {}

class Competitions extends React.Component<Props, State> {
  state = {}

  onChangeCompetition = ({ value: competitionId }) => {
    history.push(`/competitions/${competitionId}`)
  }

  onSubmit = values => {
    const { resources, competitionId } = this.props
    return resources
      .competitions({
        id: competitionId,
        name: values.name,
      })
      .update()
  }

  onSubmitProviders = providers => {
    const { resources, competitionId } = this.props
    return resources
      .competitions({
        id: competitionId,
        providers,
      })
      .update()
  }

  onSave = data => {
    const { resources, competitions, competitionConnectors } = this.props
    const { connector, ...restData } = data

    const competition = competitions[data.id]

    if (connector.id === null && competition.connector) {
      resources
        .competitionConnectors({
          id: competition.connector.id,
          competitionId: null,
          isActive: false,
        })
        .update()
    } else {
      const competitionConnectorLinkedWithCurrentConnector = find(competitionConnectors, competitionConnector => {
        return competitionConnector.competitionId === competition.id && competitionConnector.id !== connector.id
      })

      if (competitionConnectorLinkedWithCurrentConnector) {
        // remove link between connector and competition in order to avoid 2 connector with the same competition
        resources
          .competitionConnectors({
            id: competitionConnectorLinkedWithCurrentConnector.id,
            competitionId: null,
            isActive: false,
          })
          .update()
      }

      resources
        .competitionConnectors({
          id: connector.id,
          competitionId: competition.id,
          isActive: connector.isActive,
        })
        .update()
    }

    // if restData contain id and at leat another key
    if (Object.keys(restData).length > 1) {
      return resources.competitions(restData).update()
    }

    return Promise.resolve()

    // if (connector) {
    //   if (!competition.connector) {
    //     resources
    //       .connectors({
    //         ...connector,
    //         competitionId: competition.id,
    //       })
    //       .create()
    //   } else {
    //     resources
    //       .connectors({
    //         id: competition.connector.id,
    //         ...data.connector,
    //       })
    //       .update()
    //   }
    // }

    // return resources.competitions(restData).update()
  }

  render() {
    const { countries, competitions, competitionConnectors } = this.props

    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <ReactTable
            data={Object.values(competitions)}
            defaultSorted={[{ id: 'name', desc: false }]}
            onSave={this.onSave}
            columns={[
              Edit(),
              {
                Header: 'awayfootball',
                columns: [
                  Text({
                    Header: 'Name',
                    accessor: 'name',
                    filterable: true,
                    enableEdit: true,
                  }),
                  Select({
                    Header: 'Country',
                    id: 'countryId',
                    accessor: item => countries[item.countryId].name,
                    accessorEdit: 'countryId',
                    options: ObjectValues(countries).map(country => ({
                      value: country.id,
                      label: country.name,
                    })),
                    filterable: true,
                    enableEdit: true,
                    width: 200,
                  }),
                ]
              },
              {
                Header: 'Provider api-football',
                columns: [
                  Select({
                    Header: 'Name',
                    id: 'apiDashFootballId',
                    accessor: item => {
                      const { connector } = item
                      if (!connector) return ''

                      const { name, season } = connector.plainData

                      return `${name} (${season})`
                    },
                    accessorEdit: 'connector.id',
                    options: (row) => {
                      const filteredOptions = ObjectValues(competitionConnectors).filter(option => {
                        const country = countries[row.original.countryId];
                        const { plainData } = option

                        const isLastOf2YearSeason = plainData.season > new Date().getFullYear() - 2;
                        const isSameCountry = plainData.country === country.name;

                        return isSameCountry && isLastOf2YearSeason;
                      });

                      return [
                        {
                          value: null,
                          label: 'None',
                        },
                        ...filteredOptions
                        .sort((a, b) => a.plainData.season > b.plainData.season ? -1 : 1)
                        .map(connector => {
                          const { name, season, country } = connector.plainData
                          return {
                            value: connector.id,
                            label: `${name} (${season}) - ${country}`,
                          }
                        }),
                      ]
                    },
                    filterable: true,
                    enableEdit: true,
                  }),
                  Select({
                    Header: 'Status',
                    id: 'apiDashFootballStatus',
                    width: 100,
                    accessor: item => {
                      const { connector } = item

                      if (!connector) {
                        return null;
                      }

                      return connector.isActive ? '✅  Active' : '❌  Disabled'
                    },
                    accessorEdit: 'connector.isActive',
                    options: [{ label: '✅  Active', value: true }, { label: '❌  Disabled', value: false }],
                    filterable: true,
                    enableEdit: true,
                  }),
                ],
              },
            ]}
          />
        </div>
      </div>
    )
  }
}

export default pipe()
  .resources()
  .connect((state, props) => ({
    countries: getResources(state, 'countries', undefined, ['competitions']),
    competitions: getResourcesFromRequest(state, 'competitions', 'fetch', ['connector']),
    competitionId: props.match.params.competitionId,
    competitionConnectors: state.competitionConnectors.resources,
  }))
  .request(({ resources }) => {
    return Promise.all([
      resources.countries().fetch(),
      resources.competitions().fetch({
        requestKey: 'fetch',
        params: { filter: { include: 'connector' } },
      }),
      resources.competitionConnectors().fetch(),
    ])
  })
  .render(Competitions)
