// @flow
import React, { createContext, useContext } from 'react'
import resources from 'store/resources'
import { mapValues } from 'lodash'

export const StoreContext = createContext(null)

export function HookStoreProvider(props: any) {
  return <StoreContext.Provider value={props.store}>{props.children}</StoreContext.Provider>
}

export function useStore() {
  return useContext(StoreContext)
}

export function useResources() {
  const store = useStore()

  const resourcesBinded = mapValues(resources, resource => {
    return data =>
      mapValues(resource(data), action => {
        return config => action(config)(store.dispatch)
      })
  })

  return resourcesBinded
}
