// @flow
import * as React from 'react'
import { css } from 'emotion'

type Props = {
  children: React.Node,
}

const styles = css`
  padding: 10px 0;
  color: var(--color-red);
`

export default class Message extends React.PureComponent<Props> {
  render() {
    return <div className={styles}>{this.props.children}</div>
  }
}
