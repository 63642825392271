// @flow
import { mapValues } from 'lodash'
import { actionTypes } from '@guillaumejasmin/redux-resource'

const actionCreateKeys = {
  read: 'read',
  create: 'create',
  update: 'update',
  delete: 'delete',
}

const statuses = {
  idle: 'idle',
  pending: 'pending',
  succeeded: 'succeeded',
  failed: 'failed',
}

export default mapValues(actionCreateKeys, type => {
  return {
    ...mapValues(
      statuses,
      status =>
        actionTypes[`${type.toUpperCase()}_RESOURCES_${status.toUpperCase()}`],
    ),
    local:
      type === 'delete'
        ? actionTypes.DELETE_RESOURCES
        : actionTypes.UPDATE_RESOURCES,
  }
})

// default export look like this:
//
// {
//   read: {
//     idle: 'READ_RESOURCES_IDLE',
//     pending: 'READ_RESOURCES_PENDING',
//     succeeded: 'READ_RESOURCES_SUCCEEDED',
//     failed: 'READ_RESOURCES_FAILED',
//     local: 'UPDATE_RESOURCE'
//   }
//   ...
// }
